import {Product} from '../model/product';
import {CartProduct} from '../model/cart';

export class Util {
  static isNonNull<T>(value: T): value is NonNullable<T> {
    return value !== null;
  }

  static convertCartProductsToFullProducts(cartProducts: CartProduct[], products: Optional<Product[]>) {
    return cartProducts.map((cartProduct) => ({
      ...products?.find(product => product.id === cartProduct.id),
      ...cartProduct,
    } as Product))
  }
}
