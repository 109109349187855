<button *ngIf="!CookieManager.noConsentsGiven"
        mat-button
        mat-icon-button
        class="close"
        (click)="dialogRef.close()">
  <mat-icon>close</mat-icon>
</button>


<section *ngIf="!managingCookies && CookieManager.noConsentsGiven">
  <mat-dialog-content>
    <h3>Miło nam przywitać Cię w naszym sklepie internetowym! </h3>

    <p>Na samym początku chcielibyśmy poinformować Cię o naszych „ciasteczkach”, czyli tzw. polityce cookies. Klikając w
       link przeniesiesz się do naszej podstrony, która poinformuje Cię czym owa polityka cookies jest i „z czym je się
       owe ciasteczka”. </p>
    <p>W tym miejscu pragniemy podkreślić, że stosujemy pliki cookie przede wszystkim w celu zapewnienia prawidłowego
       funkcjonowania serwisu. Może zdarzyć się jednak i tak, że będziemy wykorzystywać własne pliki cookie oraz te
       należące do naszych partnerów. Będą one wykorzystywane w celach analitycznych i marketingowych, w szczególności
       dopasowania treści reklamowych do Twoich preferencji. </p>
    <p>Wszystko jest w Twoich rękach, ponieważ korzystanie z analitycznych i marketingowych plików cookie wymaga Twojej
       zgody. Możesz ją wyrazić, klikając przycisk pt. „ZAAKCEPTUJ”. Jeżeli jednak chcesz dostosować swoje zgody dla nas
       i
       naszych partnerów, kliknij przycisk pt. „ZARZĄDZAJ COOKIES”.</p>
    <p><strong>Pamiętaj, że wyrażoną zgodę możesz wycofać w każdym momencie, zmieniając wybrane ustawienia. </strong>
    </p>
    <p>Korzystanie z plików cookie we wskazanych powyżej celach związane jest z przetwarzaniem Twoich danych
       osobowych. </p>
    <p>Administratorem Twoich danych osobowych jest Aneta Mierzejewska prowadząca jednoosobową działalność
       gospodarczą
       pod firmą PRACOWNIA PSYCHOLOGICZNA "RÓWNOWAGA" dr ANETA MIERZEJEWSKA, z siedzibą przy ul. Broniewskiego 43, 07-415 Olszewo-Borki,
       Nip: 7582371205. </p>
    <p>W pewnych przypadkach administratorami danych mogą być również nasi partnerzy. Więcej informacji o korzystaniu
       przez nas i naszych partnerów z plików cookie oraz o przetwarzaniu Twoich danych osobowych, w tym o
       przysługujących
       Ci uprawnieniach, znajdziesz w naszej Polityce Cookies. </p>
  </mat-dialog-content>
  <footer>
    <button mat-button
            class="button-big button-secondary"
            (click)="managingCookies = true">Zarządzaj cookies
    </button>
    <button #acceptButton
            mat-button
            class="button-big button-primary"
            (click)="acceptAllAndClose()">Zaakceptuj
    </button>
  </footer>
</section>

<section *ngIf="managingCookies || !CookieManager.noConsentsGiven">
  <mat-dialog-content>
    <h3>Szanujemy Twoją prywatność. Zawsze masz wybór.</h3>

    <p>Poniżej możesz zmienić ustawienia plików cookies naszych i naszych partnerów.</p>

    <p><strong>Korzystanie przez nas z plików cookie analitycznych oraz marketingowych wymaga Twojej zgody. </strong>
    </p>

    <p>W ramach cookies marketingowych wydzieliliśmy kategorię plików cookie związanych z wyświetlaniem reklam w związku
       z
       korzystaniem ze stron internetowych (reklamowe pliki cookie) oraz plików cookie pozwalających na docieranie do
       Ciebie ze spersonalizowaną reklamą w portalach społecznościowych (pliki cookie mediów społecznościowych). Więcej
       informacji o poszczególnych kategoriach plików cookie, które stosujemy w serwisie, znajdziesz poniżej. </p>

    <p>Jeżeli chcesz zaakceptować wszystkie pliki cookie, kliknij przycisk pt. „ZAAKCEPTUJ WSZYSTKO”. Jeżeli natomiast
       chcesz, żebyśmy stosowali tylko niezbędne pliki cookie, kliknij „ZAPISZ USTAWIENIA I PRZEJDŹ DO SKLEPU”. </p>

    <p><strong>Aby zmieniać preferencje plików cookie, przesuń suwak przy wybranej kategorii. Masz prawo do wglądu w
               swoje
               ustawienia oraz do ich zmiany w dowolnym czasie. </strong></p>

    <p>Korzystanie z plików cookie wiąże się z przetwarzaniem Twoich danych osobowych dotyczących Twojej aktywności w
       serwisie. Szczegółowe informacje o sposobie, w jaki my oraz nasi partnerzy używamy plików cookie oraz
       przetwarzamy
       Twoje dane, a także o przysługujących Ci prawach, znajdziesz w naszej Polityce Cookies. </p>

    <section class="accept-button-container">
      <button mat-button
              class="button-big button-primary"
              (click)="acceptAllAndClose()">Zaakceptuj wszystko
      </button>
    </section>

    <h4>Zarządzaj cookies:</h4>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <span>Niezbędne cookie</span>
      </mat-expansion-panel-header>
      <p>Korzystanie przez nas z niezbędnych plików cookie jest konieczne dla prawidłowego funkcjonowania strony
         internetowej. Pliki te są instalowane w szczególności na potrzeby zapamiętywania sesji logowania lub
         wypełniania
         formularzy, a także w celach związanych ustawieniem opcji prywatności. </p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div>
          <span>Analityczne cookie</span>
          <mat-slide-toggle [checked]="analyticConsent"
                            (click)="$event.stopPropagation()"
                            (change)="analyticConsent = $event.checked"></mat-slide-toggle>
        </div>
      </mat-expansion-panel-header>
      <p>Analityczne pliki cookie umożliwiają sprawdzenie liczby wizyt i źródeł ruchu w naszym sklepie internetowym.
         Pomagają nam ustalić, które części naszego sklepu (jak również produkty) są bardziej, a które mniej popularne.
         Chcemy zrozumieć, jak użytkownicy – w tym także i Ty - poruszają się po stronie i co ich interesuję
         najbardziej.
         Dzięki temu możemy badać statystki i poprawiać wydajność naszej witryny spełniając Twoje oczekiwania i stając
         się
         coraz lepsi w tym co robimy. Informacje zbierane przez te pliki cookie są agregowane, <strong>nie mają więc na
                                                                                                       celu
                                                                                                       ustalenia Twojej
                                                                                                       tożsamości. </strong>
      </p>

      <p><strong>Jeśli nie zezwolisz na stosowanie tych plików cookie, nie będziemy wiedzieć, kiedy odwiedziłeś naszą
                 stronę
                 internetową. </strong></p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div>
          <span>Funkcjonalne cookie</span>
          <mat-slide-toggle [checked]="functionalConsent"
                            (click)="$event.stopPropagation()"
                            (change)="functionalConsent = $event.checked"></mat-slide-toggle>
        </div>
      </mat-expansion-panel-header>
      <p>Funkcjonalne pliki cookie zapamiętują i dostosowują stronę do Twoich wyborów, np. preferencji językowych. </p>

      <p><strong>Możesz ustawić przeglądarkę tak, aby blokowała lub ostrzegała o niezbędnych i funkcjonalnych plikach
                 cookie, jednak spowoduje to, że niektóre części witryny mogą nie działać prawidłowo. </strong></p>
    </mat-expansion-panel>

    <mat-error>
      <ul *ngIf="!functionalConsent">
        Odznaczone cookies funkcjonalne uniemożliwią Tobie:
        <li>Korzystanie z paczkomatów</li>
        <li>Płatność poprzez płatności online</li>
      </ul>
    </mat-error>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div>
          <span>Reklamowe cookie</span>
          <mat-slide-toggle [checked]="marketingConsent"
                            (click)="$event.stopPropagation()"
                            (change)="marketingConsent = $event.checked"></mat-slide-toggle>
        </div>
      </mat-expansion-panel-header>
      <p>Reklamowe pliki cookie pozwalają na dopasowanie wyświetlanych treści reklamowych do Twoich zainteresowań,
        <strong>nie tylko na naszej witrynie, ale też poza nią.</strong></p>

      <p>Mogą być instalowane przez partnerów reklamowych za pośrednictwem naszej strony internetowej. Na podstawie
         informacji z tych plików cookie i aktywności w innych serwisach jest budowany Twój profil zainteresowań. </p>

      <p>Reklamowe cookies nie przechowują bezpośrednio Twoich danych osobowych, ale identyfikują Twoją przeglądarkę
         internetową i sprzęt.</p>

      <p><strong>Jeśli nie zezwolisz na stosowanie tych plików cookie, dalej będziemy mogli wyświetlać Ci reklamy,
                 jednak
                 nie będą one dopasowane do Twoich preferencji.</strong></p>
    </mat-expansion-panel>
  </mat-dialog-content>

  <section class="accept-button-container">
    <button mat-button
            class="button-big button-primary"
            (click)="acceptModifiedAndClose()">Zapisz ustawienia i przejdź do sklepu
    </button>
  </section>
</section>

