import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {CookieManager} from 'app/shared/cookie-manager';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-cookies-dialog',
  templateUrl: './cookies-dialog.component.html',
  styleUrls: ['./cookies-dialog.component.scss']
})
export class CookiesDialogComponent {
  readonly CookieManager = CookieManager;
  managingCookies = false;

  @ViewChild('acceptButton') set acceptButton(el: Optional<MatButton>) {
    el?._elementRef.nativeElement.focus();
  };

  analyticConsent = CookieManager.analyticStorageItem === null
    ? true
    : CookieManager.analyticStorageItem === CookieManager.CONSENT_ACCEPTED;

  marketingConsent = CookieManager.marketingStorageItem === null
    ? true
    : CookieManager.marketingStorageItem === CookieManager.CONSENT_ACCEPTED;

  functionalConsent = CookieManager.functionalStorageItem === null
    ? true
    : CookieManager.functionalStorageItem === CookieManager.CONSENT_ACCEPTED;

  constructor(public dialogRef: MatDialogRef<CookiesDialogComponent>,
              private cookieManager: CookieManager) {
  }

  acceptAllAndClose() {
    this.cookieManager.applyConsents(true, true, true);
    this.dialogRef.close();
  }

  acceptModifiedAndClose() {
    this.cookieManager.applyConsents(this.functionalConsent, this.analyticConsent, this.marketingConsent);
    this.dialogRef.close();
  }
}
