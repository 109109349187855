import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, rootEffectsInit} from '@ngrx/effects';
import {ShoppingFacade} from './shopping.facade';
import {shoppingActions} from './shopping.actions';
import {exhaustMap, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {ShoppingDataService} from '../service/shopping.data.service';
import {MatDialog} from '@angular/material/dialog';
import {SimpleDialogComponent, SimpleDialogData} from '../../shared/component/simple-dialog/simple-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Overlay} from '@angular/cdk/overlay';
import {authActions} from '../../auth/state/auth.actions';
import {AuthFacade} from '../../auth/state/auth.facade';
import {PackageMachineSelectComponent} from '../component/package-machine-select/package-machine-select.component';
import {DeliveryType} from '../../model/delivery-type';
import {PaymentType} from '../../model/payment-type';
import {CheckoutStep} from '../../model/checkout-step';
import {Router} from '@angular/router';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Cart} from '../../model/cart';

@Injectable({
  providedIn: 'root'
})
export class ShoppingEffects {

  readonly getCategories$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.getCategories),
    switchMap(() => this.dataService.getCategories()),
    map((categories) => shoppingActions.saveCategories({categories})),
  ))

  readonly getProducts$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.getProducts),
    switchMap(() => this.dataService.getProducts()),
    map((products) => shoppingActions.saveProducts({products})),
  ))

  readonly getDeliveries$ = createEffect(() => this.actions$.pipe(
    ofType(rootEffectsInit),
    switchMap(() => this.dataService.getDeliveries()),
    map((deliveries) => shoppingActions.saveDeliveries({deliveries})),
  ))

  readonly getCart$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.getCart),
    switchMap(({cartId}) => this.dataService.getCart(cartId)),
    map((cart) => shoppingActions.saveCart({cart})),
  ))

  readonly createCart$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.createCart),
    switchMap(() => this.dataService.createCart().pipe(
      tap((cart) => localStorage.setItem('babilas_cart_id', cart.id))
    )),
    map(({id}) => shoppingActions.getCart({cartId: id})),
  ))

  readonly changeStepOnUserUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.saveUser),
    withLatestFrom(
      this.facade.checkoutStep$,
      this.facade.paymentIntentNullable$,
    ),
    map(([{user}, checkoutStep, paymentIntent]) => {
      if (paymentIntent && user?.deliveryData?.paymentType === PaymentType.Online) {
        return CheckoutStep.Summary;
      } else if (!paymentIntent && (user?.deliveryData?.paymentType === PaymentType.Cod || user?.deliveryData?.paymentType === PaymentType.Transfer)) {
        return CheckoutStep.Summary;
      } else if (user) {
        return CheckoutStep.Delivery;
      } else {
        return CheckoutStep.Login
      }
    }),
    map((step) => shoppingActions.changeCheckoutStep({step})),
  ))

  readonly goToSummary$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.goToSummary),
    withLatestFrom(
      this.facade.cart$,
      this.authFacade.presentUser$,
    ),
    switchMap(([{deliveryData}, cart, user]) => deliveryData.paymentType === PaymentType.Online
      ? this.dataService.generatePaymentIntent(user.uid, cart, deliveryData).pipe(
        map((paymentIntent) => shoppingActions.savePaymentIntent({paymentIntent}))
      ) : this.dataService.saveDeliveryData(user.uid, deliveryData).pipe(
        map(() => shoppingActions.deliveryDataSaved())
      )),
  ))

  readonly updateDeliveryData$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.updateDeliveryData),
    withLatestFrom(
      this.authFacade.presentUser$,
    ),
    switchMap(([{deliveryData}, user]) => this.dataService.saveDeliveryData(user.uid, deliveryData)),
  ), {dispatch: false})

  readonly addProductToCart$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.addProductToCart),
    withLatestFrom(this.facade.cart$),
    switchMap(([{cartProduct}, cart]) => this.dataService.addProductToCart(cartProduct, cart).pipe(
      switchMap(() => this.matSnackBar.open('Produkt dodany do koszyka', 'Złóż zamówienie').onAction().pipe(
        tap(() => this.router.navigate(['/', 'checkout']))
      ))
    )),
  ), {dispatch: false})

  readonly blockCheckoutOnEmptyCart$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.saveCart),
    withLatestFrom(this.facade.checkoutStep$),
    filter(([{cart}, checkoutStep]) => !cart?.products?.length
      && this.router.url.includes('checkout')
      && checkoutStep !== CheckoutStep.Summary),
    tap(() => this.router.navigate(['/', 'home'])),
  ), {dispatch: false})

  readonly changeDeliveryType$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.changeDeliveryType),
    withLatestFrom(this.facade.selectedPackageMachine$),
    filter(([{deliveryType}, selectedPackageMachine]) => !selectedPackageMachine && deliveryType === DeliveryType.PackageMachine),
    exhaustMap(([{deliveryType}]) => this.matDialog.open(PackageMachineSelectComponent, {
      scrollStrategy: this.overlay.scrollStrategies.block(),
      disableClose: true,
    }).afterClosed().pipe(
      tap(() => scrollToBottomOfDeliveryMethod()),
      map((packageMachine) => shoppingActions.selectPackageMachine({packageMachine}))
    )),
  ))

  readonly clearSelectedPackageMachine$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.clearSelectedPackageMachine),
    exhaustMap(() => this.matDialog.open(PackageMachineSelectComponent, {
      scrollStrategy: this.overlay.scrollStrategies.block(),
      disableClose: true,
    }).afterClosed().pipe(
      tap(() => scrollToBottomOfDeliveryMethod()),
      filter(packageMachine => !!packageMachine),
      map((packageMachine) => shoppingActions.selectPackageMachine({packageMachine}))
    )),
  ))

  readonly removeProductFromCart$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.removeProductFromCart),
    withLatestFrom(this.facade.cart$),
    switchMap(([{productId}, cart]) => {
      return cart.products?.length === 1 && this.router.url.includes('checkout')
        ? this.warnLastProductRemoval(productId, cart)
        : this.removeProductAndIndicateChange(productId, cart)
    }),
  ), {dispatch: false})

  readonly confirmOrder$ = createEffect(() => this.actions$.pipe(
    ofType(shoppingActions.confirmOrder),
    withLatestFrom(
      this.authFacade.presentUser$,
      this.facade.cart$,
    ),
    switchMap(([_, user, cart]) => this.dataService.createOrder(user, cart).pipe(
      switchMap(() => fromPromise(this.router.navigate(['/', 'confirmation'])))
    )),
  ), {dispatch: false})

  constructor(private actions$: Actions,
              private dataService: ShoppingDataService,
              private matDialog: MatDialog,
              private matSnackBar: MatSnackBar,
              private overlay: Overlay,
              private authFacade: AuthFacade,
              private facade: ShoppingFacade,
              private router: Router) {
  }

  private getSimpleDialog(data: SimpleDialogData) {
    return this.matDialog.open(SimpleDialogComponent, {data, scrollStrategy: this.overlay.scrollStrategies.noop()}).afterClosed()
  }

  private warnLastProductRemoval(productId: string, cart: Cart) {
    const message = `Usunięcie ostatniego produktu spowoduje anulowanie zamówienia. Czy chcesz kontynuować?`

    return this.getSimpleDialog({message}).pipe(
      filter(accepted => !!accepted),
      switchMap(() => this.removeProductAndIndicateChange(productId, cart))
    )
  }

  private removeProductAndIndicateChange(productId: string, cart: Cart) {
    return this.dataService.removeProductFromCart(productId, cart).pipe(
      exhaustMap(() => this.matSnackBar.open(`Usunięto produkt z koszyka`, cart?.products.length <= 1 ? undefined : 'Złóż zamówienie')
        .onAction().pipe(
          tap(() => this.router.navigate(['/', 'checkout']))
        ))
    )
  }
}

function scrollToBottomOfDeliveryMethod() {
  setTimeout(() => {
    const el = document.querySelector('.delivery-method');
    const scrollSize = (el?.getBoundingClientRect()?.bottom ?? 0) + window.scrollY
    window.scroll({top: scrollSize, behavior: 'smooth'})
  }, 150)
}
