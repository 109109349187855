<button mat-button
        mat-icon-button
        class="close"
        (click)="dialogRef.close()">
  <mat-icon>close</mat-icon>
</button>

<h2>Kontakt</h2>

<p>Masz do nas pytania? Skorzystaj z formularza dostępnego poniżej!</p>

<p>Wolisz porozmawiać? Zadzwoń pod nasz numer: <a href="tel:508199662">508 199 662</a></p>

<h3>Formularz kontaktowy</h3>

<form [formGroup]="formGroup">
  <mat-form-field>
    <mat-label>Email</mat-label>
    <input type="text"
           matInput
           formControlName="email"
           name="email">
    <mat-error *ngIf="formGroup.get('email')?.getError('required')">Uzupełnij adres email</mat-error>
    <mat-error *ngIf="formGroup.get('email')?.getError('email')">Adres email jest nieprawidłowy</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Temat</mat-label>
    <input type="text"
           matInput
           formControlName="subject"
           name="subject">
    <mat-error *ngIf="formGroup.get('email')?.getError('required')">Uzupełnij tytuł</mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Treść</mat-label>
    <textarea matInput
              formControlName="content"
              name="content"></textarea>
    <mat-error *ngIf="formGroup.get('content')?.getError('required')">Uzupełnij treść</mat-error>
  </mat-form-field>
  <button mat-button
          class="button-primary button-big"
          (click)="$event.preventDefault(); sendContactEmail()">Wyślij
  </button>
</form>
