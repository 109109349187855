import {createAction, props} from '@ngrx/store';
import {User} from '../../model/user';

const fetchAllUsers = createAction('[Admin] Fetch all users');
const saveAllUsers = createAction('[Admin] Save all users', props<{users: User[]}>());
const saveUser = createAction('[Auth] Save user', props<{user: Optional<User>}>())
const updateUser = createAction('[Auth] Update user', props<{user: Optional<Partial<User>>}>())
const changeOrderStatus = createAction('[Auth] Change order status', props<{userId: string, orderId: string}>())
const getUser = createAction('[Auth] Get user')
const signInWithFacebook = createAction('[Auth] Sign in with facebook')
const signInWithEmailAndPassword = createAction('[Auth] Sign in with email and password', props<{email: string, password: string}>())
const signUpWithEmailAndPassword = createAction('[Auth] Sign up with email and password', props<{email: string, password: string}>())
const sendPasswordResetEmail = createAction('[Auth] Send password reset email', props<{email: string}>())
const signInWithGoogle = createAction('[Auth] Sign in with google')
const signInAnonymously = createAction('[Auth] Sign in anonymously')
const logout = createAction('[Auth] Logout')
const deleteAccount = createAction('[Auth] Delete account')
const accountDeleted = createAction('[Auth] Account deleted')

export const authActions = {
  saveUser,
  signInWithFacebook,
  signInWithEmailAndPassword,
  signUpWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithGoogle,
  signInAnonymously,
  updateUser,
  getUser,
  logout,
  fetchAllUsers,
  saveAllUsers,
  changeOrderStatus,
  deleteAccount,
  accountDeleted,
}
