import {createAction, props} from '@ngrx/store';
import {Category} from '../../model/category';
import {Product} from '../../model/product';
import {Cart, CartProduct} from '../../model/cart';
import {CheckoutType} from '../../model/checkout-type';
import {DeliveryType} from '../../model/delivery-type';
import {PackageMachine} from '../../model/package-machine';
import {ConfirmP24PaymentData, PaymentIntent} from '@stripe/stripe-js';
import {DeliveryData} from '../../model/delivery-data';
import {PaymentType} from '../../model/payment-type';
import {Delivery} from '../../model/delivery';
import {CheckoutStep} from '../../model/checkout-step';

const getCategories = createAction('[Shopping] Get categories');
const getProducts = createAction('[Shopping] Get products');
const saveDeliveries = createAction('[Shopping] Save deliveries', props<{deliveries: Delivery[]}>());
const saveCategories = createAction('[Shopping] Save categories', props<{ categories: Category[] }>());
const saveProducts = createAction('[Shopping] Save products', props<{ products: Product[] }>());
const getCart = createAction('[Shopping] Get cart', props<{ cartId: string }>());
const createCart = createAction('[Shopping] Create cart');
const saveCart = createAction('[Shopping] Save cart', props<{ cart: Cart }>());
const addProductToCart = createAction('[Shopping] Add product to cart', props<{ cartProduct: CartProduct }>());
const removeProductFromCart = createAction('[Shopping] Remove product from cart', props<{ productId: string }>());
const changeCheckoutType = createAction('[Shopping] Change checkout type', props<{ checkoutType: CheckoutType }>());
const changeDeliveryType = createAction('[Shopping] Change delivery type', props<{ deliveryType: DeliveryType }>());
const changePaymentType = createAction('[Shopping] Change payment type', props<{ paymentType: PaymentType }>());
const selectPackageMachine = createAction('[Shopping] Select package machine', props<{ packageMachine: PackageMachine }>());
const clearSelectedPackageMachine = createAction('[Shopping] Clear selected package machine');
const performPayment = createAction('[Shopping] Perform payment');
const goToSummary = createAction('[Shopping] Go to summary', props<{deliveryData: DeliveryData}>());
const updateDeliveryData = createAction('[Shopping] Update delivery data', props<{deliveryData: DeliveryData}>());
const changeCheckoutStep = createAction('[Shopping] Change checkout step', props<{ step: Optional<CheckoutStep> }>());
const resolveCheckoutStep = createAction('[Shopping] Resolve checkout step');
const savePaymentIntent = createAction('[Shopping] Save payment intent', props<{ paymentIntent: PaymentIntent }>());
const submitPayment = createAction('[Shopping] Submit payment', props<{ clientSecret: string, data: ConfirmP24PaymentData }>());
const deliveryDataSaved = createAction('[Shopping] Delivery data saved');
const confirmOrder = createAction('[Shopping] Confirm order');

export const shoppingActions = {
  getCategories,
  saveCategories,
  getProducts,
  saveDeliveries,
  saveProducts,
  getCart,
  createCart,
  saveCart,
  addProductToCart,
  removeProductFromCart,
  changeCheckoutType,
  changeDeliveryType,
  selectPackageMachine,
  clearSelectedPackageMachine,
  performPayment,
  goToSummary,
  changeCheckoutStep,
  savePaymentIntent,
  deliveryDataSaved,
  submitPayment,
  changePaymentType,
  resolveCheckoutStep,
  confirmOrder,
  updateDeliveryData,
};
