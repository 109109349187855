<app-header [user]="user$ | async">
  <app-cart cart
            [products]="productsFromCart$ | async"
            [totalSum]="totalSum$ | async"
            (quantityChange)="changeProductQuantity($event)"
            (productRemove)="removeProductFromCart($event)"></app-cart>
</app-header>
<main [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</main>

<mat-progress-spinner *ngIf="(user$ | async) === undefined"
                      mode="indeterminate"
                      [diameter]="100"></mat-progress-spinner>

<footer>
  <button mat-button
          class="button-secondary cookies-button"
          (click)="openCookiesDialog()"
          matTooltip="Tutaj możesz zarządzać swoimi ustawieniami cookies">Cookies
  </button>
</footer>
