import {BehaviorSubject, combineLatest, from, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieManager {
  functional = new BehaviorSubject<string | null>(CookieManager.functionalStorageItem);
  functional$ = this.functional.asObservable();
  analytic = new BehaviorSubject<string | null>(CookieManager.analyticStorageItem);
  analytic$ = this.analytic.asObservable();
  marketing = new BehaviorSubject<string | null>(CookieManager.marketingStorageItem);
  marketing$ = this.marketing.asObservable();

  noConsentsGiven$ = combineLatest([
    this.functional$,
    this.analytic$,
    this.marketing$
  ]).pipe(
    map(([functional, analytic, marketing]) => !functional || !analytic || !marketing)
  )

  static CONSENT_ACCEPTED = 'ACCEPTED';
  static CONSENT_DECLINED = 'DECLINED';

  functionalConsents$ = this.functional$.pipe(
    map((functional) =>
      ({accepted: functional === CookieManager.CONSENT_ACCEPTED, declined: functional === CookieManager.CONSENT_DECLINED}))
  )

  static get functionalStorageItem() {
    return localStorage.getItem('functional_cookie_consent');
  }

  static get analyticStorageItem() {
    return localStorage.getItem('analytic_cookie_consent');
  }

  static get marketingStorageItem() {
    return localStorage.getItem('marketing_cookie_consent');
  }

  static get noConsentsGiven() {
    return !CookieManager.functionalStorageItem || !CookieManager.analyticStorageItem || !CookieManager.marketingStorageItem;
  }

  applyConsents(functional: boolean, analytic: boolean, marketing: boolean) {
    localStorage.setItem('functional_cookie_consent', functional ? CookieManager.CONSENT_ACCEPTED : CookieManager.CONSENT_DECLINED);
    this.functional.next(functional ? CookieManager.CONSENT_ACCEPTED : CookieManager.CONSENT_DECLINED);
    localStorage.setItem('analytic_cookie_consent', analytic ? CookieManager.CONSENT_ACCEPTED : CookieManager.CONSENT_DECLINED);
    this.analytic.next(analytic ? CookieManager.CONSENT_ACCEPTED : CookieManager.CONSENT_DECLINED);
    localStorage.setItem('marketing_cookie_consent', marketing ? CookieManager.CONSENT_ACCEPTED : CookieManager.CONSENT_DECLINED);
    this.marketing.next(marketing ? CookieManager.CONSENT_ACCEPTED : CookieManager.CONSENT_DECLINED);
  }
}
