import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FooterComponent} from './component/footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {RouterModule} from '@angular/router';
import {SimpleDialogComponent} from './component/simple-dialog/simple-dialog.component';
import {NgxStripeModule} from 'ngx-stripe';
import {NumericDirective} from './numeric.directive';
import {DeliveryFormComponent} from './component/delivery-form/delivery-form.component';
import {DeliveryAddressComponent} from './component/delivery-address/delivery-address.component';
import {CartContentComponent} from './component/cart-content/cart-content.component';
import {AddProductComponent} from './component/add-product/add-product.component';
import {LoginComponent} from './component/login/login.component';
import {CookiesDialogComponent} from './component/cookies-dialog/cookies-dialog.component';
import { FaqComponent } from './component/faq/faq.component';
import { PrivacyPolicyComponent } from './component/privacy-policy/privacy-policy.component';
import { RulesComponent } from './component/rules/rules.component';
import { ContactComponent } from './component/contact/contact.component';
import {NgxMaskModule} from 'ngx-mask';
import {environment} from '../../environments/environment';
import {ResetPasswordDialogComponent} from '../auth/component/reset-password-dialog/reset-password-dialog.component';


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    SimpleDialogComponent,
    NumericDirective,
    DeliveryFormComponent,
    DeliveryAddressComponent,
    CartContentComponent,
    AddProductComponent,
    LoginComponent,
    CookiesDialogComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    RulesComponent,
    ContactComponent,
    ResetPasswordDialogComponent,
  ],
  imports: [
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    RouterModule,
    NgxMaskModule,
    NgxStripeModule.forRoot(environment.stripeKey),
  ],
  exports: [
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    FooterComponent,
    HeaderComponent,
    SimpleDialogComponent,
    NgxStripeModule,
    NumericDirective,
    DeliveryFormComponent,
    DeliveryAddressComponent,
    CartContentComponent,
    AddProductComponent,
    LoginComponent,
    CookiesDialogComponent,
    ResetPasswordDialogComponent,
  ]
})
export class SharedModule {
}
