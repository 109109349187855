<section>
  <h3>Najczęściej zadawane pytania</h3>

  <article>
    <h4>a) Jakie są podstawy prawne reklamacji?</h4>
    <p>Zgłaszając reklamację Klient ma możliwość wyboru następujących podstaw prawnych: gwarancji (o ile została
       udzielona) albo rękojmi.</p>
    <h4>b) Jak wygląda postępowanie reklamacyjne w ramach reklamacji z gwarancji?</h4>
    <p>Warunki dochodzenia praw z tytułu gwarancji określone są w przepisach ustawy Kodeks cywilny (dalej jako:
      <strong>kc</strong>). Przepisy, o których mowa znajdziesz w Tytule XI, Dział III, art. 577 - 582 kc.</p>
    <p>W ramach gwarancji odpowiedzialność za wadliwy towar ponosi producent lub dystrybutor. Udziela on gwarancji
       dobrowolnie, co oznacza, że samodzielnie określa zakres uprawnień oraz swojej odpowiedzialności, a także termin
       gwarancji. Za rozpatrzenie reklamacji zgłoszonej z tytułu gwarancji odpowiada gwarant, a nie sprzedawca.
       Szczegółowe informacje na temat postępowania reklamacyjnego zawarte są w oświadczeniu gwarancyjnym.</p>
    <p><strong>podstawa prawna:</strong> art. 577 kc, art. 5771 kc, art. 5772 kc.</p>
    <h4>c) Jak wygląda postępowanie reklamacyjne w ramach reklamacji z rękojmi?</h4>
    <p>Warunki dochodzenia praw z tytułu rękojmi określone są w przepisach ustawy Kodeks cywilny (dalej jako:
      <strong>kc</strong>). Przepisy, o których mowa znajdziesz w Tytule XI, Dział II art. 556- 576 kc.</p>
    <p>W ramach rękojmi odpowiedzialność za wadliwy towar spoczywa na sprzedawcy. Sprzedawca odpowiada z tytułu rękojmi,
       jeżeli wada fizyczna zostanie stwierdzona przed upływem dwóch lat od dnia wydania rzeczy kupującemu. Klient
       powinien zgłosić wadę do sprzedawcy w terminie 1 roku od daty jej stwierdzenia. W przypadku Konsumenta, termin na
       złożenie reklamacji nie może jednak zakończyć się przed upływem 2-letniego okresu odpowiedzialności sprzedawcy.
       Na
       podstawie dokumentu potwierdzającego zakup (paragon lub jego zdjęcie czy też skan, potwierdzenie z konta, numer
       zamówienia itp.), Klient może wnosić o bezpłatną naprawę towaru, jego wymianę, obniżkę ceny albo zwrot
       gotówki.</p>
    <p><strong>podstawa prawna:</strong> art. 568 § 1 kc, art. 568 § 2 kc, art. 560 kc, art. 561 kc.</p>
    <p><strong>Ważne!</strong> Od dnia 1 stycznia 2021 r. osoby fizyczne, w tym wspólnicy spółek cywilnych będący
                               przedsiębiorcami i
                               nabywający Produkty poza ich zawodowym charakterem działalności mają prawo do odstąpienia
                               od umowy zawieranej na
                               odległość oraz rozpatrywania roszczeń z tytułu rękojmi jak konsumenci.</p>
    <h4>d) W jakim terminie Sprzedawca rozpatruje reklamację z rękojmi?</h4>
    <p>Sprzedawca ma 14 dni na rozpatrzenie reklamacji w zakresie wymiany, usunięcia wady albo oświadczenia o obniżeniu
       ceny i poinformowanie Konsumenta o swojej decyzji, inaczej uważa się, że uznał żądanie Konsumenta za
       uzasadnione.</p>
    <p><strong>podstawa prawna:</strong> art. 5615 kc.</p>
    <p><strong>Ważne!</strong> Termin wydania decyzji nie jest tożsamy z terminem zakończenia obsługi serwisowej oraz
                               procesu reklamacyjnego.</p>

    <h4>e) Co jeśli reklamacja jest już zgłoszona z tytułu gwarancji?</h4>
    <p>W przypadku dochodzenia roszczenia z tytułu gwarancji, Klient nie straci uprawnienia do dochodzenia tego samego
       roszczenia w ramach rękojmi. Jednakże w razie wykonywania przez kupującego uprawnień z gwarancji bieg terminu do
       wykonania uprawnień z tytułu rękojmi ulega zawieszeniu z dniem zawiadomienia sprzedawcy o wadzie. Termin ten
       biegnie dalej od dnia odmowy przez gwaranta wykonania obowiązków wynikających z gwarancji albo bezskutecznego
       upływu czasu na ich wykonanie.</p>
    <p><strong>podstawa prawna:</strong> art. 579 kc.</p>

    <h4>f) Czy po wymianie towaru na nowy okres odpowiedzialności ulega wydłużeniu?</h4>

    <p>Po wymianie towaru na nowy, rękojmia nie ulega wydłużeniu. Czas gwarancji liczony jest od nowa od momentu wydania
       nowego towaru i nie może być krótszy niż pierwotna gwarancja.</p>

    <p><strong>podstawa prawna:</strong> art. 581 kc.</p>

    <h4>g) Otrzymałem uszkodzony towar – złożyć reklamację czy odstąpienie?</h4>

    <p>Prawo odstąpienia od umowy odnosi się do zwrotu towaru przez Konsumenta w przypadku zakupu w sklepie
       internetowym,
       w terminie do 14 dni od jego otrzymania bez podawania przyczyny odstąpienia.</p>

    <p><strong>podstawa prawna:</strong> art. 27 ustawy o prawach konsumenta.</p>

    <p><strong>Ważne!</strong> Od dnia 1 stycznia 2021 r. osoby fizyczne, w tym wspólnicy spółek cywilnych będący
                               przedsiębiorcami i
                               nabywający Produkty poza ich zawodowym charakterem działalności mają prawo do odstąpienia
                               od umowy zawieranej na
                               odległość oraz rozpatrywania roszczeń z tytułu rękojmi jak konsumenci.</p>

    <p>Jeśli zamówiony towar został uszkodzony w transporcie, np. widoczne są ślady pięknieć czy połamanych elementów,
       Klient również może zgłosić reklamację.</p>

    <p><strong>podstawa prawna:</strong> art. 5561 k.c.</p>

    <p>Konsument ponosi odpowiedzialność za zmniejszenie wartości rzeczy będące wynikiem korzystania z niej w sposób
       wykraczający poza konieczny do stwierdzenia charakteru, cech i funkcjonowania rzeczy</p>

    <p><strong>podstawa prawna:</strong> art. 34 pkt. 4 ustawy o prawach konsumenta.</p>

    <h4>h) Jak mogę otrzymać zwrot środków za wysyłkę towaru do Sprzedawcy w przypadku złożenia reklamacji wadliwego
        towaru?</h4>

    <p>W przypadku zasadnej reklamacji zgłoszonej w ramach rękojmi, Klientowi przysługuje zwrot kosztów za wysyłkę
       wadliwego towaru.</p>

    <p><strong>podstawa prawna:</strong> art. 5612 § 1 kc.</p>

    <p>W tym celu należy przesłać zdjęcie lub skan dokumentu potwierdzającego poniesione koszty oraz numer konta
       bankowego
       wraz z nazwą banku za pośrednictwem <a (click)="openContactDialog()">formularza kontaktowego</a>.</p>

    <p>Jeśli reklamacja została zgłoszona z tytułu gwarancji, wniosek o zwrot kosztów za wysyłkę towaru do reklamacji
       należy kierować bezpośrednio do gwaranta.</p>

    <p><strong>podstawa prawna:</strong> art. 580 § 1 kc.</p>

    <h4>i) Czy można zmienić reklamację na odstąpienie od umowy zawartej na odległość, gdy towar został już
        wysłany?</h4>

    <p>Tak, jeśli oświadczenie o odstąpieniu zostało złożone w terminie 14 dni od wydania towaru.</p>

    <h4>j) Czy odsyłając produkt na reklamację mogę wysłać tylko wadliwy element czy muszę wysyłać komplet?</h4>

    <p>Zgłaszając reklamację produktu będącego jedną całością – należy wysłać całość. W przypadku pojedynczych
       produktów,
       można reklamować jedynie te, które są wadliwe.</p>

    <h4>k) Nie mam oryginalnego opakowania - czy wciąż mogę zgłosić reklamację?</h4>

    <p>Brak oryginalnego opakowania nie wpływa na możliwość zgłoszenia reklamacji towaru.</p>

    <h4>Co oznacza informacja, że Klient zostanie rozliczony zgodnie z warunkami gwarancji?</h4>

    <p>W przypadku uznania reklamacji zgłoszonej z gwarancji, Klient zostanie rozliczony zgodnie z jej warunkami
       określonymi w oświadczeniu gwarancyjnym.</p>

    <p><strong>podstawa prawna:</strong> art. 5771 § 1 kc.</p>

    <h4>m) Czy można zwrócić towar zakupiony w sklepie stacjonarnym?</h4>

    <p>Towar, który został zakupiony w sklepie stacjonarnym, zgodnie z obowiązującym prawem nie podlega odstąpieniu od
       umowy z tytułu zakupu na odległość.</p>

    <h4>n) Czy można zwrócić towar zakupiony w sklepie internetowym?</h4>

    <p>Klient może zwrócić towar zakupiony w sklepie internetowym w ramach odstąpienia od umowy zawartej na
       odległość.</p>

    <h4>o) W jaki sposób można złożyć reklamację?</h4>

    <p>W przypadku stwierdzenia wady fizycznej towaru, Klient może zgłosić reklamację:</p>
    <ul>
      <li>bezpośrednio w naszej siedzibie, tj. (…)</li>
      <li>za pośrednictwem <a (click)="openContactDialog()">formularza kontaktowego</a>
      </li>
      <li>za pośrednictwem infolinii Sprzedawcy;</li>
      <li>przesłać towar na adres: (…) Należy pamiętać o dołączeniu do przesyłki opisu usterki oraz dowodu zakupu (np.
          kopii faktury, kopii paragonu, numer zamówienia).
      </li>
    </ul>

    <h4>p) Ile czasu wynosi gwarancja na mój towar?</h4>

    <p>Okres trwania gwarancji zależy od gwaranta i jest on określony w oświadczeniu gwarancyjnym. Jeżeli jednak gwarant
       nie wskazał czasu jej trwania, przyjmuje się, że wynosi on dwa lata - licząc od dnia wydania towaru.</p>

    <p><strong>podstawa prawna:</strong> art. 577 kc.</p>

    <p>Na podstawie jakich dokumentów zakupu mogę złożyć reklamację?</p>

    <p>Reklamację można złożyć na podstawie dowolnego dokumentu, który potwierdza sprzedaż, np. paragon, faktura, numer
       zamówienia itp.</p>

    <h4>q) Czy odstępując od umowy, towar zakupiony w sklepie internetowym mogę zwrócić w sklepie stacjonarnym?</h4>

    <p>Nie ma możliwości zwrotu towaru, który został zakupiony w sklepie internetowym w sklepie stacjonarnym.</p>

    <p>Towar ten należy odesłać lub przekazać na adres: (…) z dopiskiem „Odstąpienie od umowy”.</p>

    <h4>r) Czy jeśli towar został zarezerwowany w sklepie stacjonarnym mogę odstąpić od umowy?</h4>

    <p>Jeśli Klient dokonał zakupu towaru w sklepie stacjonarnym, rezerwując go wcześniej za pośrednictwem sklepu
       internetowego, to zakup taki nie stanowi umowy zawartej na odległość, od której przysługuje prawo odstąpienia z
       zachowaniem terminu 14 dniu od daty odbioru zamówienia.</p>

    <h4>s) Po jakim czasie otrzymam zwrot środków z odstąpienia?</h4>

    <p>Zgodnie z obowiązującymi przepisami Sprzedawca ma 14 dni, od daty otrzymania towaru, na zwrot należności
       Konsumentowi.</p>

    <p><strong>podstawa prawna:</strong> art. 32 ust. 1 ustawy o prawach konsumenta.</p>

    <h4>t) Towar został zwrócony w ramach odstąpienia od umowy zawartej na odległość – czy przysługuje zwrot kosztów za
        przesyłkę?</h4>

    <p>Na podstawie art. 34 ust. 1 ustawy o prawach konsumenta, Konsument ma obowiązek zwrócić rzecz przedsiębiorcy lub
       przekazać ją osobie upoważnionej przez przedsiębiorcę do odbioru niezwłocznie, jednak nie później niż 14 dni od
       dnia, w którym odstąpił od umowy. W przypadku odstąpienia od umowy zawartej na odległość koszty związane ze
       zwrotem
       towaru (koszty odesłania towaru do Sprzedawcy) ponosi (…) informuje o tym zarówno w Regulaminie Sklepu
       internetowego jak i w dokumentach wysłanych w toku zawierania umowy na trwałym nośniku.</p>
  </article>
</section>
