import {NgModule} from '@angular/core';
import {AccountDetailsContentComponent} from './component/account-details-content/account-details-content.component';
import {SharedModule} from '../shared/shared.module';
import { SignInDialogComponent } from './component/sign-in-dialog/sign-in-dialog.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
  ],
  declarations: [
    AccountDetailsContentComponent,
    SignInDialogComponent,
  ],
  exports: [
    AccountDetailsContentComponent,
  ]
})
export class AuthModule {
}
