export enum PaymentType {
  Online = 'ONLINE',
  Transfer = 'TRANSFER',
  Cod = 'COD',
}

export const paymentTypeDict = {
  [PaymentType.Transfer]: 'Przelew tradycyjny',
  [PaymentType.Cod]: 'Za pobraniem',
  [PaymentType.Online]: 'Płatność online',
}
