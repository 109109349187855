import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  formGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    subject: new FormControl(null, [Validators.required]),
    content: new FormControl(null, [Validators.required]),
  })

  constructor(private functions: AngularFireFunctions,
              public dialogRef: MatDialogRef<ContactComponent>,
              private matSnackBar: MatSnackBar) {
  }

  ngOnInit(): void {
  }

  sendContactEmail() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      return this.functions.httpsCallable('sendContactEmail')(this.formGroup.value).pipe(
        switchMap(() => this.matSnackBar.open('Wiadomość została pomyślnie wysłana!').afterOpened().pipe(
          tap(() => this.dialogRef.close())
        )),
      ).subscribe();
    } else {
      return undefined;
    }
  }
}
