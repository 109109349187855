<ng-container *ngIf="cartProduct">
  <button *ngIf="!noButton"
          mat-button
          class="button-big"
          [class.button-primary]="!cartProduct?.quantity"
          [class.button-secondary]="cartProduct?.quantity"
          (click)="cartProduct?.quantity ? productRemove.emit(cartProduct.id) : productAdd.emit({id: cartProduct.id, quantity: 1})">
    <span *ngIf="cartProduct?.quantity" class="warn">W koszyku (Usuń)</span>
    <span *ngIf="!cartProduct?.quantity">Dodaj do koszyka</span>
  </button>
  <mat-form-field *ngIf="cartProduct?.quantity">
    <mat-label>Ilość:</mat-label>
    <input #input
           numeric
           matInput
           type="tel"
           min="1"
           autocomplete="off"
           [formControl]="formControl"
           (focus)="input.value = ''"
           (blur)="input.value = formControl.value"
           (input)="addProductDebounced({id: cartProduct.id, quantity: +formControl.value})">
  </mat-form-field>
  <button *ngIf="cartProduct?.quantity && noButton"
          mat-button
          mat-icon-button
          (click)="productRemove.emit(cartProduct.id)">
    <mat-icon color="warn" matTooltip="Usuń produkt z koszyka">delete</mat-icon>
  </button>
</ng-container>
