import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AUTH_FEATURE_KEY, AuthState} from './auth.reducer';

const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

const user = createSelector(getAuthState, (state) => state.user);
const deliveryData = createSelector(user, (userState) => userState?.deliveryData);
const orders = createSelector(user, (userState) => userState?.orders);
const allUsers = createSelector(getAuthState, (state) => state.allUsers);


export const authSelectors = {
  user,
  deliveryData,
  orders,
  allUsers
};
