import {AfterViewInit, Component, ElementRef, EventEmitter, Output} from '@angular/core';
import {PackageMachine} from '../../../model/package-machine';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-package-machine-select',
  templateUrl: './package-machine-select.component.html',
  styleUrls: ['./package-machine-select.component.scss']
})
export class PackageMachineSelectComponent {
  @Output() packageMachineSelection = new EventEmitter<PackageMachine>();

  constructor(private dialogRef: MatDialogRef<PackageMachineSelectComponent>) {
  }

  close(pointSelect: any) {
    this.dialogRef.close(pointSelect);
  }
}
