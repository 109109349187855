import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AdminState} from './admin.reducer';
import {adminSelectors} from './admin.selectors';
import {adminActions} from './admin.actions';
import {Category} from '../../model/category';
import {Product} from '../../model/product';
import {Delivery} from '../../model/delivery';

@Injectable({
  providedIn: 'root'
})
export class AdminFacade {
  constructor(private store: Store<AdminState>) {
  }

  saveCategory(category: Category) {
    this.store.dispatch(adminActions.saveCategory({category}));
  }

  saveProduct(product: Product) {
    this.store.dispatch(adminActions.saveProduct({product}));
  }

  deleteCategory(categoryId: string) {
    this.store.dispatch(adminActions.deleteCategory({categoryId}));
  }

  deleteProduct(productId: string) {
    this.store.dispatch(adminActions.deleteProduct({productId}));
  }

  saveDelivery(delivery: Delivery) {
    this.store.dispatch(adminActions.saveDelivery({delivery}));
  }

  deleteDelivery(deliveryId: string) {
    this.store.dispatch(adminActions.deleteDelivery({deliveryId}));
  }
}
