import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterReducerState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {SHOPPING_FEATURE_KEY, shoppingReducer, ShoppingState} from './shopping';
import {ShoppingEffects} from './shopping/state/shopping.effects';
import {ADMIN_FEATURE_KEY, AdminEffects, adminReducer, AdminState} from '@@admin';
import {AUTH_FEATURE_KEY, authReducer, AuthState} from './auth/state/auth.reducer';
import {AuthEffects} from './auth/state/auth.effects';

export interface State {
  router: RouterReducerState,
  [SHOPPING_FEATURE_KEY]: ShoppingState;
  [ADMIN_FEATURE_KEY]: AdminState;
  [AUTH_FEATURE_KEY]: AuthState;
}

const effects = [
  ShoppingEffects,
  AdminEffects,
  AuthEffects,
];

const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  [AUTH_FEATURE_KEY]: authReducer,
  [SHOPPING_FEATURE_KEY]: shoppingReducer,
  [ADMIN_FEATURE_KEY]: adminReducer,
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: 'Babilas'
    })
  ]
})
export class StateModule {
}
