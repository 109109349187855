import {Action, createReducer, on} from '@ngrx/store';
import {authActions} from './auth.actions';
import {User} from '../../model/user';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  user?: Optional<User>;
  allUsers: Optional<User[]>;
}

export const initialState: AuthState = {
  allUsers: null,
};

const reducer = createReducer(
  initialState,
  on(authActions.saveUser, (state, {user}) => ({...state, user})),
  on(authActions.saveAllUsers, (state, {users}) => ({...state, allUsers: users})),
  on(authActions.accountDeleted, (state) => ({...state, user: null})),
);

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return reducer(state, action);
}
