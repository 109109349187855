import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {apiUrlToken} from '@@common';
import {Observable} from 'rxjs';
import {Category} from '../../model/category';
import {Product} from '../../model/product';
import {Cart, CartProduct} from '../../model/cart';
import {fromPromise} from 'rxjs/internal-compatibility';
import {mapTo} from 'rxjs/operators';
import {PaymentIntent} from '@stripe/stripe-js';
import {DeliveryData} from '../../model/delivery-data';
import {Delivery} from '../../model/delivery';
import {User} from '../../model/user';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class ShoppingDataService {
  constructor(private http: HttpClient,
              private firestore: AngularFirestore,
              private functions: AngularFireFunctions,
              @Inject(apiUrlToken) private api: string) {
  }

  generatePaymentIntent(userId: string, cart: Cart, deliveryData: DeliveryData): Observable<PaymentIntent> {
    return this.functions.httpsCallable('generatePaymentIntent')({userId, cart, deliveryData})
  }

  createOrder(user: User, cart: Cart): Observable<void> {
    return this.functions.httpsCallable('createOrder')({user, cart});
  }

  getCategories(): Observable<Category[]> {
    return this.firestore.collection<Category>('categories').valueChanges({idField: 'id'}) as Observable<Category[]>;
  }

  getProducts(): Observable<Product[]> {
    return this.firestore.collection<Product>('products').valueChanges({idField: 'id'}) as Observable<Product[]>;
  }

  getDeliveries(): Observable<Delivery[]> {
    return this.firestore.collection<Delivery>('deliveries').valueChanges({idField: 'id'}) as Observable<Delivery[]>;
  }

  getCart(cartId: string): Observable<Cart> {
    return this.firestore.doc<Cart>(`carts/${cartId}`).valueChanges({idField: 'id'}) as Observable<Cart>;
  }

  addProductToCart(cartProduct: CartProduct, cart: Cart): Observable<void> {
    return fromPromise(this.firestore.doc<Cart>(`carts/${cart.id}`)
      .set({
        id: cart.id,
        products: addProductToCart(cartProduct, cart)
      }));
  }

  saveDeliveryData(userId: string, deliveryData: DeliveryData): Observable<void> {
    return fromPromise(this.firestore.doc<Partial<User>>(`users/${userId}`)
      .set({
        deliveryData,
      }, {merge: true}));
  }

  createCart(): Observable<Cart> {
    const cart: Cart = {products: [], id: this.firestore.createId()};
    return fromPromise(
      this.firestore.doc<Cart>(`carts/${cart.id}`)
        .set(cart)
    ).pipe(mapTo(cart));
  }

  removeProductFromCart(productId: string, cart: Cart) {
    return fromPromise(this.firestore.doc<Cart>(`carts/${cart.id}`)
      .set({
        id: cart.id,
        products: cart.products.filter((product) => product.id !== productId),
      }));
  }
}

function addProductToCart(cartProduct: CartProduct, cart: Cart): CartProduct[] {
  const foundProduct = cart.products.find(savedCartProduct => savedCartProduct.id === cartProduct.id);
  return foundProduct
    ? cart.products.reduce((result, product) =>
      [...result, cartProduct.id === product.id ? {
        ...cartProduct,
        quantity: cartProduct.quantity <= 0 ? 1 : cartProduct.quantity
      } : product], [] as CartProduct[])
    : [...cart.products, cartProduct]
}
