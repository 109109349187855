<h3>Zwroty</h3>

<p><a href="https://firebasestorage.googleapis.com/v0/b/babilas-8fb45.appspot.com/o/WZO%CC%81R%20ODSTA%CC%A8PIENIA%20OD%20UMOWY.docx?alt=media&token=9482eb13-f60e-4a2a-8f83-8e2ff15a7f62">Kliknij tu, aby pobrać wzór odstąpienia od umowy</a></p>

<h4>Pouczenie o prawie do odstąpienia od umowy</h4>

<p>Mają Państwo prawo odstąpić od niniejszej umowy w terminie 14 dni bez podania jakiejkolwiek
   przyczyny, a w przypadku umowy zawartej podczas nieumówionej wizyty w miejscu zamieszkania
   lub zwykłego pobytu konsumenta albo wycieczki - w terminie 30 dni.</p>

<p>Termin do odstąpienia od umowy wygasa po upływie 14 dni od dnia w którym weszli Państwo w
   posiadanie towaru lub w którym osoba trzecia inna niż przewoźnik i wskazana przez Państwa
   weszła w posiadanie towaru, a w przypadku umowy zawartej podczas nieumówionej wizyty w
   miejscu zamieszkania lub zwykłego pobytu konsumenta albo wycieczki - po upływie 30 dni od
   dnia w którym weszli Państwo w posiadanie towaru lub w którym osoba trzecia inna niż
   przewoźnik i wskazana przez Państwa weszła w posiadanie towaru.</p>

<p>Aby skorzystać z prawa odstąpienia od umowy, muszą Państwo poinformować nas – wedle
   uznania – za pośrednictwem korespondencji wysłanej na adres: ul Władysława Broniewskiego 43, 07-415 Olszewo-Borki, telefonicznie pod numerem
   telefonu: 508 199 662< lub za pomocą wiadomości e-mail wysłanej na adres elektroniczny: biuro@empatai.pl o swojej decyzji
   o odstąpieniu od niniejszej umowy w drodze jednoznacznego oświadczenia (na przykład pismo
   wysłane pocztą lub pocztą elektroniczną).</p>

<p>Mogą Państwo skorzystać z wzoru formularza odstąpienia od umowy, jednak nie jest to
   obowiązkowe. Mogą Państwo również wypełnić i przesłać formularz odstąpienia od umowy lub
   jakiekolwiek inne jednoznaczne oświadczenie drogą elektroniczną na naszej stronie internetowej
   dostępnej pod adresem: https://empatai.pl. Jeżeli skorzystają Państwo z tej możliwości, prześlemy Państwu
   niezwłocznie potwierdzenie otrzymania informacji o odstąpieniu od umowy na trwałym nośniku
   (na przykład pocztą elektroniczną).</p>

<p>Aby zachować termin do odstąpienia od umowy, wystarczy, aby wysłali Państwo informację
   dotyczącą wykonania przysługującego Państwu prawa odstąpienia od umowy przed upływem
   terminu do odstąpienia od umowy.</p>

<h4>Skutki odstąpienia od umowy</h4>

<p>W przypadku odstąpienia od niniejszej umowy zwracamy Państwu wszystkie otrzymane od
   Państwa płatności, w tym koszty dostarczenia towaru (z wyjątkiem dodatkowych kosztów
   wynikających z wybranego przez Państwa sposobu dostarczenia innego niż najtańszy zwykły
   sposób dostarczenia oferowany przez nas), niezwłocznie, a w każdym przypadku nie później niż
   14 dni od dnia, w którym zostaliśmy poinformowani o Państwa decyzji o wykonaniu prawa
   odstąpienia od niniejszej umowy. Zwrotu płatności dokonamy przy użyciu takich samych
   sposobów płatności, jakie zostały przez Państwa użyte w pierwotnej transakcji, chyba że wyraźnie
   zgodziliście się Państwo na inne rozwiązanie; w każdym przypadku nie poniosą Państwo żadnych
   opłat w związku z tym zwrotem. Możemy wstrzymać się ze zwrotem płatności do czasu
   otrzymania towaru lub do czasu dostarczenia nam dowodu jego odesłania, w zależności od tego,
   które zdarzenie nastąpi wcześniej.</p>

<p>W przypadku odstąpienia od umowy prosimy – oprócz złożenia oświadczenia, o którym mowa
   powyżej – odesłać lub przekazać nam towar pod adres: ul Władysława Broniewskiego 43, 07-415 Olszewo-Borki,
   niezwłocznie, a w każdym razie nie
   później niż 14 dni od dnia, w którym poinformowali nas Państwo o odstąpieniu od niniejszej
   umowy. Termin jest zachowany, jeżeli odeślą Państwo towar przed upływem terminu 14 dni.
   Liczy się więc data stempla pocztowego, w którym nadacie Państwo zwrot.</p>

<p>Informujemy, że będą Państwo musieli ponieść bezpośrednie koszty zwrotu towaru.</p>
