<h3>Regulamin sklepu internetowego „Empatai”</h3>

<article>
  <header>
    <strong>§ 1</strong>
    <strong>Postanowienia ogólne</strong>
  </header>

  <section>
    <ol>
      <li>Niniejszy regulamin (dalej jako: „Regulamin”), określa zasady i warunki korzystania ze sklepu internetowego
          „Empatai” działającego pod adresem internetowym https://empatai.pl (dalej jako: „Sklep”).
      </li>
      <li>Regulamin jest regulaminem, o którym mowa w art. 8 Ustawy o świadczeniu usług drogą
          elektroniczną.://empatai.pl (dalej jako: „Sklep”).
      </li>
      <li>Sklep jest własnością Anety Mierzejewskiej, prowadzącej jednoosobową działalność gospodarczą pod nazwą:
          „Pracownia Psychologiczna "Równowaga" dr Aneta Mierzejewska, z siedzibą przy ul. Władysława Broniewskiego 43,
          07-415 Olszewo-Borki, wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, posiadającą
          NIP: 7582378822, REGON: 388521920 (dalej jako: „Sprzedawca”).
      </li>
      <li>Kontakt ze Sprzedawcą jest możliwy za pomocą:
        <ol>
          <li>poczty elektronicznej - pod adresem: biuro@empatai.pl</li>
          <li>poczty tradycyjnej - pod adresem: ul. Władysława Broniewskiego 43, 07-415 Olszewo-Borki</li>
          <li>telefonu - pod numerem: 508 199 662</li>
        </ol>
      </li>
      <li>Zgodnie z przepisami prawa Sprzedawca określa Regulamin oraz nieodpłatnie udostępnia go Użytkownikom przed
          zawarciem Umowy sprzedaży lub Umowy o świadczenie usług elektronicznych, a także – na żądanie Użytkownika – w
          taki sposób, który umożliwia pozyskanie, odtwarzanie i utrwalanie treści Regulaminu za pomocą systemu
          teleinformatycznego, którym posługuje się Użytkownik.
      </li>
      <li>W ramach wykonywanej działalności, Sprzedawca prowadzi sprzedaż Produktów, które mogą być nabywane przez
          Kupujących korzystających ze Sklepu, oraz świadczy wskazane w Regulaminie usługi drogą elektroniczną.
      </li>
      <li>Informacje o Produktach dostępnych w Sklepie, w szczególności ich opisy, parametry techniczne i użytkowe oraz
          ceny, stanowią zaproszenie do zawarcia umowy sprzedaży w rozumieniu art. 71 Kodeksu cywilnego.
      </li>
      <li>Przed rozpoczęciem korzystania ze Sklepu, Klient zobowiązany jest zapoznać się z Regulaminem oraz Polityką
          prywatności.
      </li>
      <li>8) W przypadku niezgodności treści Regulaminu z powszechnie obowiązującym prawem na terenie Rzeczpospolitej
          Polskiej – pierwszeństwo nad Regulaminem mają przepisy powszechnie obowiązującego prawa.
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 2</strong>
    <strong>Definicje</strong>
  </header>

  <section>
    <p>Użyte w Regulaminie wyrazy pisane wielką literą mają następujące znaczenie:</p>
    <ol>
      <li><strong>Cena - </strong> wartość wyrażona w jednostkach pieniężnych, którą Klient jest obowiązany zapłacić
                                   Sprzedawcy za Produkt.
      </li>
      <li><strong>Cena jednostkowa - </strong> cena Produktu ustalona za jednostkę określonego Produktu, których ilość
                                               lub liczba są wyrażone w jednostkach miar w rozumieniu ustawy Prawo o
                                               miarach;
      </li>
      <li><strong>Dane osobowe –</strong> dane osobowe w rozumieniu art. 4 pkt 1 rozporządzenia Parlamentu
                                          Europejskiego i Rady (UE) nr 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                                          ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
                                          sprawie swobodnego przepływu takich danych; wszelkie informacje o
                                          zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej (,,osobie,
                                          której dane dotyczą”); możliwa do zidentyfikowania osoba fizyczna to osoba,
                                          którą można bezpośrednio lub pośrednio zidentyfikować, w szczególności na
                                          podstawie identyfikatora takiego jak imię i nazwisko, numer identyfikacyjny,
                                          dane o lokalizacji, identyfikator internetowy lub jeden bądź kilka
                                          szczególnych czynników określających fizyczną, fizjologiczną, genetyczną,
                                          psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej.
      </li>
      <li><strong>Dzień roboczy – </strong>dzień niebędący dniem wolnym od pracy w rozumieniu przepisów ustawy z
                                            dnia 18 stycznia 1951 roku o dniach wolnych od pracy.
      </li>
      <li><strong>Klient – </strong>Użytkownik lub Kupujący.</li>
      <li><strong>Kodeks cywilny – </strong> ustawa z dnia 23 kwietnia 1964 r. Kodeks cywilny (tj. Dz.U. z 2023 r. poz.
                                             1610, z późniejszymi zmianami)
      </li>
      <li><strong>Konsument – </strong>osoba fizyczna dokonująca ze Sprzedawcą czynności prawnej niezwiązanej
                                       bezpośrednio z jej działalnością gospodarczą lub zawodową;
      </li>
      <li><strong>Konto –</strong> panel tworzony w systemie informatycznym Sklepu, umożliwiający Użytkownikowi
                                   korzystanie z jego funkcjonalności, w szczególności nabywanie Produktów;
      </li>
      <li><strong>Kupujący – </strong> osoba będąca Konsumentem, Przedsiębiorcą albo Przedsiębiorcą na prawach
                                       Konsumenta, która zawarła ze Sprzedawcą Umowę sprzedaży lub podjęła działania
                                       zmierzające do jej zawarcia;
      </li>
      <li><strong>Polityka prywatności – </strong> dokument zawierający informacje o przetwarzaniu danych osobowych
                                                   przez Sprzedawcę dostępny pod adresem:
                                                   https://empatai.pl/privacy-policy;
      </li>
      <li><strong>Produkt – </strong> fizyczny towar sprzedawany w Sklepie, który może nabyć Kupujący. Aktualnie są to:
        <ol>
          <li>książka, napisana przez dr Anetę Mierzejewską, nosząca tytuł „rozWojownik”, dostępna w Sklepie, którą może
              nabyć Kupujący;
          </li>
          <li>planer, zaprojektowany i sporządzony przez dr Anetę Mierzejewską, noszący tytuł „Planer efektywnego
              zarządzania czasem” dostępny w Sklepie,
          </li>
        </ol>
      </li>
      <li><strong>Przedsiębiorca – </strong> osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca
                                             osobowości prawnej, której przepisy szczególne przyznają zdolność prawną,
                                             prowadząca we własnym imieniu działalność gospodarczą lub zawodową;
      </li>
      <li><strong>Przedsiębiorca na prawach Konsumenta –</strong> osoba fizyczna prowadząca we własnym imieniu
                                                                  działalność gospodarczą lub zawodową, która zawarła ze
                                                                  Sprzedawcą Umowę bezpośrednio związaną z jej
                                                                  działalnością gospodarczą, nieposiadającą jednak dla
                                                                  tej osoby charakteru zawodowego, wynikającego w
                                                                  szczególności z przedmiotu wykonywanej przez nią
                                                                  działalności gospodarczej;
      </li>
      <li><strong>Regulamin -</strong> termin zdefiniowany w § 1 ust. 1) Regulaminu.</li>
      <li><strong>Sprzedawca -</strong> termin zdefiniowany w § 1 ust. 3) Regulaminu.</li>
      <li><strong>Umowa - </strong> umowa sprzedaży lub Umowa o świadczenie usługi Konta.</li>
      <li><strong>Umowa o świadczenie usługi Konta -</strong> umowa o świadczenie usług drogą elektroniczną w rozumieniu
                                                              Ustawy o świadczeniu usług drogą elektroniczną, której
                                                              przedmiotem jest nieodpłatne utworzenie i utrzymywanie
                                                              Konta przez Sprzedawcę na rzecz Użytkownika przez czas
                                                              nieoznaczony;
      </li>
      <li><strong>Umowa sprzedaży - </strong> umowa sprzedaży w rozumieniu przepisów Kodeksu cywilnego, której
                                              przedmiotem jest sprzedaż Kupującemu wybranego przez niego Produktu
                                              dostępnego w Sklepie;
      </li>
      <li><strong>Ustawa o prawie autorskim – </strong> ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach
                                                        pokrewnych (tj. Dz.U. z 2022 r. poz. 2509, z późniejszymi
                                                        zmianami).
      </li>
      <li><strong>Ustawa o prawach konsumenta - </strong> ustawa z dnia 30 maja 2014 r. (tj. Dz.U. z 2020 r. poz. 287, z
                                                          późniejszymi zmianami).
      </li>
      <li><strong>Ustawa o świadczeniu usług drogą elektroniczną - </strong> ustawa z dnia 18 lipca 2002 r. o
                                                                             świadczeniu usług drogą elektroniczną (tj.
                                                                             Dz.U. z 2020 r. poz. 344, z późniejszymi
                                                                             zmianami).
      </li>
      <li><strong>Ustawa Prawo o miarach - </strong>ustawa z dnia 11 maja 2001 r. (tj. Dz.U. z 2022 r. poz. 2063, z
                                                    późniejszymi zmianami).
      </li>
      <li><strong>Ustawa Prawo własności przemysłowej - </strong>ustawa z dnia 30 czerwca 2000 r. Prawo własności
                                                                 przemysłowej (tj. Dz.U. z 2023 r. poz. 1170, z
                                                                 późniejszymi zmianami).
      </li>
      <li><strong>Użytkownik - </strong> osoba będąca Konsumentem, Przedsiębiorcą albo Przedsiębiorcą na prawach
                                         Konsumenta, która zawarła ze Sprzedawcą Umowę o świadczenie Usługi Konta lub
                                         podjęła działania zmierzające do jej zawarcia;
      </li>
      <li><strong>Zamówienie - </strong> oświadczenie Kupującego skierowane do Sprzedawcy, które określa liczbę i rodzaj
                                         Produktów, które chce nabyć Kupujący.
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 3</strong>
    <strong>Wymagania techniczne</strong>
  </header>

  <section>
    <ol>
      <li>
        <p>W celu prawidłowego korzystania przez Klientów ze Sklepu, niezbędne jest łącznie:</p>
        <ol>
          <li>połączenie z siecią Internet;</li>
          <li>posiadanie urządzeń pozwalających na korzystanie z zasobów sieci Internet (np. komputer, smartfon,
              tablet);
          </li>
          <li>korzystanie z przeglądarki internetowej umożliwiającej wyświetlanie na ekranie urządzenia dokumentów
              hipertekstowych, powiązanych w sieci Internet przez sieciową usługę WWW oraz obsługującej język
              programowania JavaScript, a ponadto akceptującej pliki typu cookies (w szczególności: Google Chrome,
              Mozilla Firefox, Safari, Microsoft Edge, Brave, Opera);
          </li>
          <li>posiadanie aktywnego konta poczty elektronicznej (e-mail).</li>
        </ol>
      </li>
      <li>Użytkownik jest zobowiązany do korzystania ze Sklepu w sposób zgodny z przepisami prawa powszechnie
          obowiązującego, postanowieniami Regulaminu, a także z dobrymi obyczajami.
      </li>
      <li>W ramach korzystania ze Sklepu zabronione jest dostarczanie przez Użytkowników treści o charakterze
          bezprawnym, w szczególności podawania danych cudzych bez ich zgody a także dostarczanie wirusów, botów,
          „robaków”, „koni trojańskich”, bądź innych kodów komputerowych, plików lub programów (w szczególności
          automatyzujących procesy skryptów i aplikacji bądź innych kodów, plików lub narzędzi).
      </li>
      <li>Sprzedawca – mając na uwadze § 3 ust. 2) i 3) Regulaminu – przestrzega, że zgodnie z art. 14 ust. 1 Ustawy o
          świadczeniu usług drogą elektroniczną nie ponosi on odpowiedzialności za przechowywane dane, jeżeli nie wie o
          bezprawnym charakterze tychże danych lub związanej z nimi działalności, a w razie otrzymania urzędowego
          zawiadomienia lub uzyskania wiarygodnej informacji o bezprawnych charakterze tych danych lub związanej z nimi
          działalności niezwłocznie uniemożliwi dostęp do tych danych.
      </li>
      <li>Sprzedawca informuje, że wykorzystuje kryptograficzną ochronę transferu elektronicznego oraz treści cyfrowych
          przez zastosowanie właściwych środków logicznych, organizacyjnych i technicznych, w szczególności w celu
          uniemożliwienia dostępu osobom trzecim do danych, w tym przez szyfrowanie SSL, stosowanie haseł dostępu oraz
          programów antywirusowych czy przeciw niechcianemu oprogramowaniu.
      </li>
      <li>Sprzedawca informuje, że pomimo stosowania zabezpieczeń, o których mowa w §3 ust. 3) Regulaminu korzystanie z
          sieci Internet oraz usług świadczonych drogą elektroniczną może być zagrożone dostaniem się do systemu
          teleinformatycznego oraz urządzenia Klienta, szkodliwego oprogramowania lub uzyskaniem dostępu do danych
          znajdującym się na tym urządzeniu przez osoby trzecie. W celu zminimalizowania powołanego zagrożenia
          Sprzedawca zaleca stosowanie programów antywirusowych lub środków chroniących identyfikację w sieci Internet.
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 4</strong>
    <strong>Zasady korzystania ze Sklepu</strong>
  </header>

  <section>
    <ol>
      <li>Klient jest zobowiązany do korzystania ze Sklepu w sposób zgodny z przepisami prawa powszechnie
          obowiązującego, postanowieniami Regulaminu i Polityki prywatności, a także dobrymi obyczajami i zasadami
          współżycia społecznego.
      </li>
      <li>Dostarczanie przez Klienta treści o charakterze bezprawnym jest zabronione.</li>
    </ol>
  </section>

  <header>
    <strong>§ 5</strong>
    <strong>Utworzenie Konta</strong>
  </header>

  <section>
    <ol>
      <li>
        W celu utworzenia Konta, Użytkownik powinien wykonać następujące czynności:
        <ol>
          <li>wejść na stronę internetową Sklepu;</li>
          <li>kliknąć w ikonę „ludzika” (znajdującą się w prawym górnym rogu pomiędzy ikoną koperty, a ikoną koszyka
              zakupowego);
          </li>
          <li>
            wybrać tryb utworzenia Konta:
            <ol>
              <li>za pomocą konta Google,</li>
              <li>za pomocą konta anonimowego;</li>
            </ol>
          </li>
          <li>obowiązkowo zaznaczyć checkbox’a przy oświadczeniu o zapoznaniu się z Regulaminem i Polityką prywatności
              oraz akceptacji ich postanowień;
          </li>
        </ol>
      </li>
      <li>Wybranie każdej z opcji wskazanych w § 5 ust. 1) Regulaminu powoduje automatyczne utworzenie Konta. Użytkownik
          niezwłocznie uzyskuje do niego dostęp.
      </li>
      <li>Uzyskanie dostępu do Konta jest równoznaczne z zawarciem przez Użytkownika Umowy o świadczenie Usługi Konta.
      </li>
      <li>
        Po utworzeniu Konta, Użytkownik może uzupełnić zapisane na nim dane:
        <ol>
          <li>imię i nazwisko,</li>
          <li>adres do wysyłki;</li>
          <li>numer telefonu;</li>
          <li>adres e-mail.</li>
        </ol>
      </li>
      <li>Za pomocą Konta, Użytkownik może w szczególności przechowywać swoje dane oraz przeglądać złożone Zamówienia.
      </li>
      <li>Użytkownik może w każdym czasie i bez podania przyczyny wypowiedzieć Umowę o świadczenie Usługi Konta ze
          skutkiem natychmiastowym poprzez usunięcie Konta. W tym celu powinien zalogować się na Konto a następnie
          kliknąć opcję „USUŃ KONTO”.
      </li>
      <li>W przypadku korzystania przez Użytkownika z Konta w sposób sprzeczny z przepisami prawa powszechnie
          obowiązującego, postanowieniami Regulaminu lub dobrymi obyczajami, a także dostarczania przez Użytkownika
          treści o charakterze bezprawnym, Sprzedawca może usunąć Konto, co jest równoznaczne z wypowiedzeniem przez
          niego Umowy o świadczenie Usługi Konta bez zachowania okresu wypowiedzenia.
      </li>
      <li>Usunięcie Konta nie wpływa na wykonanie Umów sprzedaży zawartych przez Użytkownika przed usunięciem Konta.
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 6</strong>
    <strong>Nabywanie Produktów</strong>
  </header>

  <section>
    <ol>
      <li>Ceny Produktów dostępnych w Sklepie są wrażone w złotych polskich (PLN) i stanowią wartość brutto (zawierają
          wszystkie obowiązkowe składniki ceny, w tym należny podatek VAT).
      </li>
      <li>Kupujący nie może nabywać Produktów bez utworzeniu Konta. Kupujący może jednak utworzyć anonimowe Konto przed
          dokonaniem zakupów, które po w każdym momencie może usunąć na zasadach określonych w § 5 Regulaminu.
      </li>
      <li>
        W celu nabycia Produktu, Kupujący powinien wykonać następujące czynności:
        <ol>
          <li>wejść na stronę internetową Sklepu;</li>
          <li>zalogować się na swoje Konto (lub wybrać opcję utworzenia konta anonimowego),</li>
          <li>
            opcjonalnie – uzupełnić na swoim Koncie wymagane do realizacji zamówienia dane, tj
            <ol>
              <li>imię i nazwisko (obowiązkowo w każdym przypadku),</li>
              <li>adres do wysyłki (w przypadku chęci wyboru dostawy Produktu za pomocą kuriera),</li>
              <li>adres e-mail (obowiązkowo w każdym przypadku),</li>
              <li>numer telefonu (obowiązkowo w przypadku wyboru dostawy za pomocą urządzenia Paczkomat; opcjonalnie w
                  przypadku chęci wyboru dostawy Produktu za pomocą kuriera),
              </li>
              <li>NIP (obowiązkowo w przypadku, gdy Kupujący jest Przedsiębiorcą lub Przedsiębiorcą na prawach
                  konsumenta),
              </li>
            </ol>
          </li>
          <p>dane, o których mowa powyżej zostaną automatycznie uzupełnione w dalszej części Zamówienia (w innym
             przypadku Kupujący będzie je musiał uzupełnić samodzielnie);</p>
          <li>wejść w zakładkę wybranego Produktu i kliknąć opcję „DODAJ DO KOSZYKA”;</li>
          <li>wejść w zakładkę „KOSZYK” (ikona wózka sklepowego w prawym górnym rogu) i kliknąć opcję „ZŁÓŻ
              ZAMÓWIENIE”;
          </li>
          <li>
            w wyświetlającym się formularzu wpisać lub wybrać następujące dane:
            <ol>
              <li>sposób dostawy,</li>
              <li>sposób płatności,</li>
              <li>imię i nazwisko (obowiązkowo w każdym przypadku),</li>
              <li>adres do wysyłki (w przypadku chęci wyboru dostawy Produktu za pomocą kuriera),</li>
              <li>adres e-mail (obowiązkowo w każdym przypadku),</li>
              <li>numer telefonu (obowiązkowo w przypadku wyboru dostawy za pomocą urządzenia Paczkomat; opcjonalnie w
                  przypadku chęci wyboru dostawy Produktu za pomocą kuriera),
              </li>
              <li>NIP (obowiązkowo w przypadku, gdy Kupujący jest Przedsiębiorcą lub Przedsiębiorcą na prawach
                  konsumenta);
              </li>
            </ol>
          </li>
          <li>obowiązkowo zaznaczyć checkbox’a przy oświadczeniu o zapoznaniu się z Regulaminem i Polityką prywatności
              oraz akceptacji ich postanowień;
          </li>
          <li>w przypadku, gdy Kupujący jest Przedsiębiorcą albo Przedsiębiorcą na prawach Konsumenta - zaznaczyć
              odpowiedniego checkbox’a przy oświadczeniu o charakterze zawieranej umowy sprzedaży (zawodowym lub
              niezawodowym);
          </li>
          <li>kliknąć opcję „KUPUJE I PŁACĘ”, a następnie dokonać zapłaty za Produkt zgodnie z wybranym sposobem
              płatności.
          </li>
        </ol>
      </li>
      <li>
        Kupujący może wybrać jeden z następujących sposobów dostarczenia Produktu:
        <ol>
          <li>przesyłka kurierska InPost;</li>
          <li>dostarczenie do wybranego urządzenia Paczkomat marki InPost;</li>
        </ol>
      </li>
      <li>
        Płatności za Produkt, Kupujący może dokonać:
        <ol>
          <li>przelewem bankowym na rachunek bankowy Sprzedawcy,</li>
          <li>przelewem przy wykorzystaniu systemu płatności Przelewy24,</li>
          <li>gotówką przy odbiorze Produktu dostarczonego przez kuriera InPost.</li>
        </ol>
      </li>
      <li>Kliknięcie przez Kupującego opcji „KUPUJĘ I PŁACĘ” jest równoznaczne ze złożeniem przez niego oferty zakupu
          Produktów objętych Zamówieniem.
      </li>
      <li>Po złożeniu Zamówienia, Kupujący otrzymuje potwierdzenie jego złożenia na podany przez niego adres poczty
          elektronicznej.
      </li>
      <li>W przypadku braku możliwości realizacji Zamówienia, Sprzedawca zawiadamia o tym Kupującego za pomocą
          wiadomości przesłanej na podany przez niego adres poczty elektronicznej. W przypadku, o którym mowa w zdaniu
          poprzedzającym, pomiędzy Sprzedawcą a Kupującym nie dochodzi do zawarcia Umowy sprzedaży.
      </li>
      <li>Jeżeli Zamówienie, o którym mowa w § 6 ust. 8) Regulaminu zostało wcześniej opłacone przez Kupującego,
          Sprzedawca niezwłocznie zwraca mu wszystkie dokonane przez niego płatności.
      </li>
      <li>W przypadku przekazania Zamówienia do realizacji, Sprzedawca zawiadamia o tym Kupującego za pomocą wiadomości
          przesłanej na podany przez niego adres poczty elektronicznej. W momencie otrzymania przez Kupującego
          wiadomości, o której mowa w zdaniu poprzedzającym, między Sprzedawcą a Kupującym dochodzi do zawarcia Umowy
          sprzedaży.
      </li>
      <li>Jeżeli złożone Zamówienie, w stosunku, do którego Kupujący wybrał opcję płatności za Produkt przed jego
          dostarczeniem (opcja płatności bezpośrednio na rachunek bankowy Sprzedawcy), nie zostanie opłacone w ciągu 2
          dni roboczych liczonych od dnia jego złożenia, Sprzedawca ma prawo wstrzymać się z wysłaniem Produktu oraz
          odstąpić od Umowy sprzedaży i anulować zamówienie w terminie 24 godzin liczonych od dnia bezskutecznego upływu
          terminu na opłacenie Zamówienia.
      </li>
      <li>O odstąpieniu przez Sprzedawcę od Umowy sprzedaży i anulowaniu Zamówienia, o którym mowa w §6 ust. 11)
          Regulaminu Kupujący zostaje powiadomiony za pomocą wiadomości przesłanej na podany przez niego adres poczty
          elektronicznej.
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 7</strong>
    <strong>Realizacja Zamówienia</strong>
  </header>

  <section>
    <ol>
      <li>Sprzedawca realizuje wysyłkę Produktów pod adresy znajdujące się jedynie na terytorium Rzeczypospolitej
          Polskiej.
      </li>
      <li>Koszty wysyłki Produktu pokrywa Kupujący.</li>
      <li>
        Wysyłka Produktu następuje w terminie do 14 dni roboczych liczonych od dnia:
        <ol>
          <li>zaksięgowania płatności za Produkt na rachunku bankowym Sprzedawcy - w przypadku dokonania przez
              Kupującego płatności za Produkt przed jego dostarczeniem;
          </li>
          <li>otrzymania przez Kupującego wiadomości o przekazaniu Zamówienia do realizacji - w przypadku wybrania przez
              Kupującego opcji dokonania płatności za Produkt przy jego dostarczeniu.
          </li>
        </ol>
      </li>
      <li>Sprzedawca zobowiązany jest dostarczyć Kupującemu nabyty Produkt w stanie wolnym od wad.</li>
      <li>Produkt dostarczony Kupującemu powinien znajdować się w nienaruszonym stanie.</li>
      <li>Jeżeli Produkt jest dostarczany przez kuriera InPost, Kupujący powinien sprawdzić Produkt w jego obecności.
          Jeśli paczka Produktu jest uszkodzona, Kupujący powinien spisać protokół uszkodzenia i skontaktować się ze
          Sprzedawcą.
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 8</strong>
    <strong>Badanie zgodności Produktu z Umową sprzedaży</strong>
  </header>

  <section>
    <ol>
      <li>Postanowienia niniejszego § 8 dotyczą wyłącznie Kupującego będącego Konsumentem albo Przedsiębiorcą na prawach
          Konsumenta.
      </li>
      <li>Produkt jest zgodny z Umową sprzedaży, jeżeli zgodne z Umową sprzedaży pozostają w szczególności jego: opis,
          rodzaj, ilość, jakość, kompletność i funkcjonalność (zdolność, do pełnienia swojej funkcji z uwzględnieniem
          przeznaczenia Produktu) oraz przydatność do szczególnego celu, do którego jest potrzebny Kupującemu, o którym
          Kupujący powiadomił Sprzedawcę najpóźniej w chwili zawarcia Umowy sprzedaży i który Sprzedawca zaakceptował.
      </li>
      <li>
        Produkt, aby został uznany za zgodny z Umową sprzedaży, musi:
        <ol>
          <li>nadawać się do celów, do których zazwyczaj używa się Produktu tego rodzaju, z uwzględnieniem
              obowiązujących przepisów prawa, norm technicznych lub dobrych praktyk i zasad współżycia społecznego;
          </li>
          <li>
            występować w takiej ilości i mieć takie cechy, w tym trwałość i bezpieczeństwo, jakie są typowe dla
            Produktu tego rodzaju i których Kupujący może rozsądnie oczekiwać, biorąc pod uwagę charakter Produktu oraz
            publiczne zapewnienie złożone przez Sprzedawcę, jego poprzedników prawnych lub osoby działające w ich
            imieniu, w szczególności w reklamie lub na etykiecie, chyba że Sprzedawca wykaże, że:
            <ol>
              <li>nie wiedział o danym publicznym zapewnieniu i oceniając rozsądnie, nie mógł o nim wiedzieć,</li>
              <li>przed zawarciem Umowy sprzedaży publiczne zapewnienie zostało sprostowane z zachowaniem warunków i
                  formy, w jakich publiczne zapewnienie zostało złożone, lub w porównywalny sposób,
              </li>
              <li>publiczne zapewnienie nie miało wpływu na decyzję konsumenta o zawarciu umowy;</li>
            </ol>
          </li>
          <li>być dostarczany z opakowaniem, akcesoriami i instrukcjami, których dostarczenia Kupujący może rozsądnie
              oczekiwać i które zostały wskazane w opisie Produktu;
          </li>
          <li>być takiej samej jakości jak próbka lub wzór, które Sprzedawca udostępnił Kupującemu przed zawarciem Umowy
              sprzedaży i odpowiadać opisowi takiej próbki lub takiego wzoru.
          </li>
        </ol>
      </li>
      <li>Sprzedawca nie ponosi odpowiedzialności za brak zgodności Produktu z Umową sprzedaży w zakresie, o którym mowa
          w § 8 ust. 2) Regulaminu, jeżeli Kupujący (Konsument), najpóźniej w chwili zawarcia Umowy sprzedaży, został
          wyraźnie poinformowany (wiedza dostępna przed dokonaniem zakupu, np. w formie opisu Produktu), że konkretna
          cecha Produktu odbiega od wymogów zgodności z Umową sprzedaży określonych w § 8 ust. 2) Regulaminu, oraz
          wyraźnie i odrębnie zaakceptował (mimo informacji złożył Zamówienie) brak konkretnej cechy Produktu.
      </li>
      <li>Sprzedawca ponosi odpowiedzialność za brak zgodności Produktu z Umową sprzedaży istniejący w chwili jego
          dostarczenia i ujawniony w ciągu dwóch lat od tej chwili (rękojmia).
      </li>
      <li>Domniemywa się, że brak zgodności Produktu z Umową sprzedaży, który ujawnił się przed upływem dwóch lat od
          chwili dostarczenia Produktu, istniał w chwili jego dostarczenia, o ile nie zostanie udowodnione inaczej lub
          domniemania tego nie można pogodzić ze specyfiką Produktu lub charakterem braku zgodności Produktu z Umową
          sprzedaży.
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 9</strong>
    <strong>Prawo odstąpienia od Umowy sprzedaży</strong>
  </header>

  <section>
    <ol>
      <li>Postanowienia niniejszego § 9 dotyczą wyłącznie Kupującego będącego Konsumentem albo Przedsiębiorcą na prawach
          Konsumenta.
      </li>
      <li>Kupujący ma prawo odstąpić od Umowy sprzedaży zawartej na odległość lub poza lokalem przedsiębiorstwa bez
          podania jakiejkolwiek przyczyny i bez ponoszenia jakichkolwiek kosztów w terminie 14 dni od dnia wejścia w
          posiadanie Produktu przez niego lub wskazaną przez Konsumenta osobę trzecią, niebędącą przewoźnikiem.
      </li>
      <li>Kupujący zaznaczając chceckbox’a przy oświadczeniu o zapoznaniu się z Regulaminem i Polityką prywatności oraz
          akceptacji ich postanowień jednocześnie oświadcza, iż został poinformowany o prawie do odstąpienia od Umowy
          sprzedaży.
      </li>
      <li>Prawo odstąpienia od Umowy sprzedaży, Kupujący wykonuje poprzez złożenie Sprzedawcy oświadczenia o odstąpieniu
          od Umowy sprzedaży (dalej jako: „Oświadczenie”).
      </li>
      <li>Do zachowania terminu na odstąpienie od umowy wystarczy wysłanie Oświadczenia przed upływem terminu, o którym
          mowa w § 9 ust. 2) Regulaminu.
      </li>
      <li>Oświadczenie może być złożone przez Kupującego w jakiejkolwiek formie, w tym również na formularzu stanowiącym
          Załącznik nr 2 do Ustawy o prawach konsumenta.
      </li>
      <li>W celu usprawnienia realizacji prawa odstąpienia od Umowy sprzedaży, Sprzedawca zaleca jednak złożenie
          Oświadczenia w sposób wskazany w ustępach poniższych, którego szczegóły znajdują się również w stopce strony w
          zakładce „Zwroty i reklamacje”.
      </li>
      <li>Kupujący może złożyć Oświadczenie w formie elektronicznej lub papierowej.</li>
      <li>W przypadku wyboru Oświadczenia w formie papierowej, Kupujący powinien wydrukować i wypełnić formularz
          znajdujący się w stopce strony w zakładce „Zwroty i reklamacje”, a następnie wysłać go wraz z Produktem pod
          adres wskazany w § 1 ust. 4) lit. b) Regulaminu.
      </li>
      <li>
        W przypadku wyboru Oświadczenia w formie elektronicznej, Kupujący powinien przesłać pocztą elektroniczną pod
        adres wskazany w § 1 ust. 4) lit. a) Regulaminu.
        <ol>
          <li>skan wypełnionego formularza znajdującego się w stopce strony w zakładce „Zwroty i reklamacje”,</li>
          lub
          <li>
            wiadomość zawierającą następujące elementy:
            <ol>
              <li>imię i nazwisko Kupującego,</li>
              <li>adres zamieszkania Kupującego,</li>
              <li>numer telefonu Kupującego,</li>
              <li>adres poczty elektronicznej Kupującego,</li>
              <li>wyraźne oświadczenie o odstąpieniu od Umowy sprzedaży,</li>
              <li>numer Zamówienia,</li>
              <li>wskazanie Produktów, którego dotyczy odstąpienie od Umowy sprzedaży,</li>
              <li>ilość sztuk,</li>
              <li>wartość Zamówienia,</li>
              <li>data złożenia Zamówienia,</li>
              <li>data otrzymania Produktu.</li>
            </ol>
          </li>
          <li>Sprzedawca przesyła Kupującemu potwierdzenie otrzymania Oświadczenia niezwłocznie po jego otrzymaniu, za
              pomocą poczty elektronicznej na adres wskazany przez Kupującego podczas składania Zamówienia.
          </li>
          <li>W przypadku skorzystania z prawa odstąpienia od Umowy sprzedaży, Kupujący powinien odesłać Produkt
              Sprzedawcy w terminie 14 dni liczonych od dnia dokonania odstąpienia od Umowy sprzedaży. Do zachowania
              terminu, o którym mowa w zdaniu poprzedzającym, wystarczy odesłanie Produktu przed jego upływem.
          </li>
          <li>Odesłanie Produktu powinno nastąpić pod adres wskazany w § 1 ust. 4) lit. b) Regulaminu z dopiskiem
              „Zwrot”.
          </li>
          <li>Kupujący ponosi bezpośrednie koszty zwrotu Produktu.</li>
          <li>Jeżeli Sprzedawca nie zaproponował, że sam odbierze towar od Kupującego, może on wstrzymać się ze zwrotem
              płatności otrzymanych od Kupującego do chwili otrzymania Produktu z powrotem lub dostarczenia przez
              Kupującego dowodu jego odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej.
          </li>
          <li>Zwrot płatności dokonywany jest przy użyciu takiego samego sposobu płatności, jaki został użyty przez
              Kupującego w pierwotnej transakcji, chyba że Kupujący wyraźnie zgodzi się na inne rozwiązanie. Kupujący
              nie ponosi kosztów zwrotu dokonanej płatności.
          </li>
          <li>Kupujący ma obowiązek zwrócić Produkt Sprzedawcy lub przekazać go osobie upoważnionej przez Sprzedawcę do
              odbioru niezwłocznie, jednak nie później niż 14 dni liczonych od dnia, w którym odstąpił od Umowy
              sprzedaży, chyba że Sprzedawca zaproponował, że sam odbierze Produkt;
          </li>
          <li>Kupujący ponosi bezpośrednie koszty zwrotu Produktu, chyba że Sprzedawca zgodził się je ponieść lub nie
              poinformował Kupującego o konieczności poniesienia tych kosztów.
          </li>
          <li>Kupujący zaznaczając chceckbox’a przy oświadczeniu o zapoznaniu się z Regulaminem i Polityką prywatności
              oraz akceptacji ich postanowień jednocześnie oświadcza, że został poinformowany o konieczności poniesienia
              kosztów, o których mowa powyżej.
          </li>
          <li>Jeżeli Umowę sprzedaży zawarto poza lokalem przedsiębiorstwa a Produkt dostarczono Klientowi do miejsca, w
              którym zamieszkiwał w chwili zawarcia Umowy sprzedaży, Sprzedawca jest zobowiązany do odebrania Produktu
              na swój koszt, gdy ze względu na charakter Produktu nie można go odesłać w zwykły sposób pocztą.
          </li>
          <li>Kupujący ponosi odpowiedzialność za zmniejszenie wartości Produktu będące wynikiem korzystania z niego w
              sposób wykraczający poza konieczny do stwierdzenia charakteru, cech i funkcjonowania Produktu, chyba że
              Sprzedawca nie poinformował Kupującego o prawie do odstąpienia od Umowy sprzedaży.
          </li>
          <li>Kupujący zaznaczając chceckbox’a przy oświadczeniu o zapoznaniu się z Regulaminem i Polityką prywatności
              oraz akceptacji ich postanowień jednocześnie oświadcza, że Sprzedawca poinformował Kupującego o prawie do
              odstąpienia od Umowy sprzedaży zgodnie z przepisami wynikającymi z Ustawy o prawach konsumenta;
          </li>
        </ol>
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 10</strong>
    <strong>Reklamacje dotyczące Produktów</strong>
  </header>

  <section>
    <ol>
      <li>Sprzedawca jest odpowiedzialny względem Kupującego z tytułu rękojmi opisanej szczegółowo w §11 Regulaminu,
          jeżeli nabyty przez Kupującego Produkt ma wadę.
      </li>
      <li>Realizacja uprawnień Kupującego wynikających z rękojmi następuje zgodnie z przepisami rozdziałów 5A Ustawy o
          prawach konsumenta.
      </li>
      <li>
        Kupujący zgłaszający reklamację z powodu wad Produktu, powinien – celem sprawniejszego jej rozpatrzenia –
        przesłać wiadomość do Sklepu na jeden ze wskazanych środków komunikacji określony w § 1 ust. 4) lit. a) – c)
        Regulaminu wiadomość, zawierającą następujące elementy:
        <ol>
          <li>imię i nazwisko Kupującego,</li>
          <li>adres zamieszkania Kupującego,</li>
          <li>numer telefonu Kupującego,</li>
          <li>adres poczty elektronicznej Kupującego,</li>
          <li>numer Zamówienia Kupującego,</li>
          <li>dokładny opis przyczyn złożenia reklamacji,</li>
          <li>wskazanie Produktów, którego dotyczy zgłoszenie reklamacyjne,</li>
          <li>ilość sztuk,</li>
          <li>wartość Zamówienia,</li>
          <li>data otrzymania Zamówienia,</li>
          <li>żądanie Kupującego,</li>
          <li>dowód zakupu Produktu lub jego kserokopię.</li>
        </ol>
      </li>
      <li>Sprzedawca, przy wykorzystaniu jednego ze wskazanych w § 1 ust. 4) lit. a) – c) Regulaminu środków
          komunikacji, przedstawi swoje stanowisko oraz umówi się z Kupującym celem ustalenia miejsca i czasu odbioru
          produktu podlegającego reklamacji.
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 11</strong>
    <strong>Rękojmia Produktu</strong>
  </header>

  <section>
    <ol>
      <li>Postanowienia niniejszego § 11 dotyczą wyłącznie Kupującego będącego Konsumentem albo Przedsiębiorcą na
          prawach Konsumenta oraz nie wykluczają żadnego z postanowień traktujących o rękojmi w polskim prawie.
      </li>
      <li>Jeżeli Produkt jest niezgodny z Umową sprzedaży, Kupujący może żądać jego naprawy lub wymiany.</li>
      <li>Sprzedawca może dokonać wymiany, gdy Kupujący żąda naprawy, jak również Sprzedawca może dokonać naprawy, gdy
          Kupujący żąda wymiany, jeżeli doprowadzenie do zgodności Produktu z Umową sprzedaży w sposób wybrany przez
          Kupującego jest niemożliwe albo wymagałoby nadmiernych kosztów występujących po stronie Sprzedawcy.
      </li>
      <li>Jeżeli naprawa i wymiana są niemożliwe lub wymagałyby nadmiernych kosztów występujących po stronie Sprzedawcy,
          może on odmówić doprowadzenia Produktu do zgodności z Umową sprzedaży.
      </li>
      <li>Przy ocenie nadmierności kosztów występujących po stronie Sprzedawcy uwzględnia się wszelkie okoliczności
          sprawy, w szczególności znaczenie braku zgodności Produktu z Umową sprzedaży, wartość Produktu zgodnego z
          Umową sprzedaży oraz nadmierne niedogodności dla Kupującego powstałe wskutek zmiany sposobu doprowadzenia
          Produktu do zgodności z Umową sprzedaży.
      </li>
      <li>Sprzedawca dokonuje naprawy lub wymiany Produktu w rozsądnym czasie liczonym od chwili, w której został
          poinformowany przez Kupującego o braku zgodności Produktu z Umową sprzedaży i bez nadmiernych niedogodności
          dla Kupującego, uwzględniając specyfikę Produktu oraz obiektywnie rozsądny cel, w jakim Kupujący go nabył.
      </li>
      <li>Koszty naprawy lub wymiany, w tym w szczególności koszty opłat pocztowych, przewozu, robocizny i materiałów,
          ponosi Sprzedawca.
      </li>
      <li>Kupujący udostępnia Sprzedawcy Produkt podlegający naprawie lub wymianie.</li>
      <li>Sprzedawca odbiera od Kupującego Produkt na swój koszt.</li>
      <li>Kupujący nie jest zobowiązany do zapłaty za zwykłe korzystanie z Produktu, który następnie został
          wymieniony.
      </li>
      <li>
        Jeżeli Produkt jest niezgodny z Umową sprzedaży, Kupujący może złożyć oświadczenie o obniżeniu ceny albo
        odstąpieniu od Umowy, gdy:
        <ol>
          <li>Sprzedawca odmówił doprowadzenia Produktu do zgodności z Umową sprzedaży,</li>
          <li>Sprzedawca nie doprowadził Produktu do zgodności z Umową sprzedaży,</li>
          <li>brak zgodności Produktu z Umową sprzedaży występuje nadal, mimo że Sprzedawca próbował doprowadzić Produkt
              do zgodności z Umową sprzedaży;
          </li>
          <li>brak zgodności Produktu z Umową sprzedaży jest na tyle istotny, że uzasadnia obniżenie Ceny albo
              odstąpienie od Umowy sprzedaży bez uprzedniego skorzystania ze środków ochrony określonych powyżej;
          </li>
          <li>z oświadczenia Sprzedawcy lub okoliczności wyraźnie wynika, że nie doprowadzi on Produktu do zgodności z
              Umową sprzedaży w rozsądnym czasie lub bez nadmiernych niedogodności dla Kupującego;
          </li>
        </ol>
      </li>
      <li>Obniżona Cena musi pozostawać w takiej proporcji do Ceny wynikającej z Umowy sprzedaży, w jakiej wartość
          Produktu niezgodnego z Umową sprzedaży pozostaje do wartości Produktu zgodnego z Umową sprzedaży.
      </li>
      <li>Sprzedawca zwraca Kupującemu kwoty należne wskutek skorzystania z prawa obniżenia Ceny niezwłocznie, nie
          później niż w terminie 14 dni liczonych od dnia otrzymania oświadczenia Kupującego o obniżeniu Ceny.
      </li>
      <li>Kupujący nie może odstąpić od Umowy sprzedaży, jeżeli brak zgodności Produktu z Umową sprzedaży jest
          nieistotny (np. drobne rysy na okładce książki, delikatne zagniecenia związane z przesyłką w paczce, itp. nie
          wpływające na możliwość korzystania z Produktu zgodnie z jego przeznaczeniem).
      </li>
      <li>Jeżeli brak zgodności z Umową sprzedaży dotyczy jedynie niektórych Produktów dostarczonych na podstawie Umowy
          sprzedaży Kupujący może odstąpić od Umowy sprzedaży jedynie w odniesieniu do tych Produktów, a także w
          odniesieniu do innych Produktów nabytych przez Kupującego wraz z Produktami niezgodnymi z Umową sprzedaży,
          jeżeli nie można rozsądnie oczekiwać, aby Kupujący zgodził się zatrzymać wyłącznie Produkty zgodne z Umową
          sprzedaży.
      </li>
      <li>W razie odstąpienia od Umowy sprzedaży Kupujący niezwłocznie zwraca Produkt Sprzedawcy na jego koszt.</li>
      <li>W przypadku spełnienia się przesłanek określonych w § 11 ust. 15) Regulaminu Sprzedawca zwraca Kupującemu Cenę
          niezwłocznie, nie później niż w terminie 14 dni liczonych od dnia otrzymania Produktu lub dowodu jego
          odesłania.
      </li>
      <li>Sprzedawca dokonuje zwrotu Ceny przy użyciu takiego samego sposobu zapłaty, jakiego użył Kupujący, chyba że
          Kupujący wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami.
      </li>
      <li>Kupujący może powstrzymać się z zapłatą Ceny do chwili wykonania przez Sprzedawcę obowiązku naprawy, wymiany,
          oświadczenia o obniżeniu ceny lub odstąpienia od Umowy sprzedaży.
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 12</strong>
    <strong>Reklamacje dotyczące usług świadczonych drogą elektroniczną</strong>
  </header>

  <section>
    <ol>
      <li>Użytkownik może złożyć reklamację w przypadku, gdy Sprzedawca świadczy usługi drogą elektroniczną w sposób
          niezgodny z Regulaminem, czego skutkiem jest w szczególności nieprawidłowe działanie Sklepu lub któregokolwiek
          z jego elementów (np. Konta).
      </li>
      <li>Sprzedawca świadczy Usługi zgodnie z Regulaminem i Polityką Prywatności.</li>
      <li>W przypadku niedotrzymania przez Sprzedawcę zobowiązania, o którym mowa w § 12 ust. 3) Regulaminu Użytkownik
          może złożyć do Sprzedawcy reklamację.
      </li>
      <li>Użytkownik składający reklamację z powodów wskazanych w § 12 ust. 1) Regulaminu, powinien przesłać pocztą
          elektroniczną pod adres wskazany w § 1 ust. 4) lit. a) Regulaminu wiadomość zawierającą następujące elementy:
        <ol>
          <li>imię i nazwisko Użytkownika;</li>
          <li>adres poczty elektronicznej;</li>
          <li>opis dostrzeżonych nieprawidłowości.</li>
        </ol>
      </li>
      <li>Sprzedawca po otrzymaniu reklamacji, ustosunkuje się do niej w terminie 14 dni kalendarzowych.</li>
    </ol>
  </section>

  <header>
    <strong>§ 13</strong>
    <strong>Własność intelektualna Sprzedawcy</strong>
  </header>

  <section>
    <ol>
      <li>Jakiekolwiek korzystanie z własności intelektualnej Sprzedawcy bez jego uprzedniego, wyraźnego zezwolenia jest
          zabronione.
      </li>
      <li>Wszelkie utwory, opisy usług, znaki towarowe, zdjęcia i opisy Produktów, zasady działania Sklepu, wszystkie
          jego elementy graficzne, interfejs, oprogramowanie, kod źródłowy, bazy danych oraz inne materiały znajdujące
          się w Sklepie stanowią własność intelektualną Sprzedawcy lub uprawnionych podmiotów trzecich. Ich
          wykorzystywanie bez zgody Sprzedawcy lub uprawnionych podmiotów trzecich będzie stanowić naruszenie praw
          autorskich.
      </li>
      <li>Sklep należy traktować jak każdy inny utwór podlegający ochronie prawno-autorskiej. Użytkownik nie ma prawa
          kopiować Sklepu z wyjątkiem przypadków dozwolonych przepisami bezwzględnie wiążącego prawa. Użytkownik
          zobowiązuje się także nie modyfikować, adaptować, tłumaczyć, odkodowywać, dekompilować, dezasemblować lub w
          jakikolwiek inny sposób starać się ustalić kod źródłowy Sklepu, z wyjątkiem przypadków dozwolonych przepisami
          bezwzględnie wiążącego prawa.
      </li>
      <li>Wszystkie dostępne w Sklepie znaki handlowe (towarowe i usługowe) Sprzedawcy oraz podmiotów trzecich powinny
          być używane zgodnie z obowiązującymi przepisami prawa.
      </li>
      <li>Użytkownik nie jest uprawniony do rozpowszechniania jakichkolwiek elementów znajdujących się w Sklepie lub
          otrzymanych od Sprzedawcy materiałów w ramach świadczonych usług w inny sposób niż w granicach dozwolonego
          użytku osobistego w rozumieniu Ustawy o prawie autorskim i prawach pokrewnych, Ustawy Prawo własności
          przemysłowej oraz innych przepisów prawa powszechnie obowiązującego w tym przepisów prawa Unii Europejskiej
          oraz nie jest uprawniony do ich dalszej odsprzedaży.
      </li>
      <li>Jakiekolwiek korzystanie z własności intelektualnej Sprzedawcy bez jego uprzedniego, wyraźnego zezwolenia jest
          zabronione.
      </li>
      <li>Uzyskując dostęp do Sklepu i świadczonych za jego pośrednictwem Usług, Użytkownik nie nabywa jakichkolwiek
          praw, w tym w szczególności licencji do utworów, znaków towarowych i innych materiałów, z wyjątkiem wypadków
          wprost wskazanych w Umowie sprzedaży, Regulaminie i Polityce Prywatności.
      </li>
    </ol>
  </section>

  <header>
    <strong>§ 14</strong>
    <strong>Ochrona danych osobowych</strong>
  </header>

  <section>
    <ol>
      <li>Zgodnie z przepisami rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w
          sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE (Dz. U. UE. L. z 2016 r. nr 119, str. 1) (dalej jako: RODO)
          administratorami danych osobowych uzyskanych przy świadczeniu usług jest Sprzedawca.
      </li>
      <li>Dane osobowe przetwarzane są przez Sprzedawcę w związku z realizacją postanowień niniejszego Regulaminu, w
          celach, przez okres i w oparciu o podstawy oraz zasady wskazane w Polityce prywatności.
      </li>
      <li>Pełna treść Polityki Prywatności, gdzie administratorem danych osobowych jest Sprzedawca znajduje się pod
          adresem: https://empatai.pl/privacy-policy
      </li>
      <li>Polityka Prywatności zawiera przede wszystkim zasady dotyczące przetwarzania danych osobowych przez Sprzedawcę
          w Sklepie znajdującym się w domenie: empatai.pl, w tym podstawy, cele i okres przetwarzania tych danych
          osobowych oraz prawa osób, których te dane dotyczą, jak również informacje w zakresie stosowania plików
          cookie’s oraz narzędzi analitycznych.
      </li>
      <li>Korzystanie ze Sklepu oraz podanie danych osobowych przez Użytkownika jest dobrowolne, z zastrzeżeniem
          wyjątków wskazanych w Regulaminie i Polityce Prywatności.
      </li>
      <li>Sprzedawca pozyskując dane osobowe za pośrednictwem Sklepu jest zobowiązany przetwarzać te dane zgodnie z
          powszechnie obowiązującymi przepisami, jak również zobowiązuje się dołożyć wszelkiej staranności w celu
          ochrony tychże danych osobowych i zobowiązuje się także ich nie wykorzystywać w innych celach niż do
          korzystania ze Sklepu, znajdującego się w domenie: empatai.pl oraz przy świadczeniu usług dostępnych w tymże
          Sklepie, chyba że podstawa takiego działania ma umocowanie w innych powszechnie obowiązujących przepisach
          prawa.
      </li>
      <li>Kontakt ze Sprzedawcą jest możliwy za pomocą:
        <ol>
          <li>poczty elektronicznej - pod adresem: biuro@empatai.pl</li>
          <li>poczty tradycyjnej - pod adresem: ul. Władysława Broniewskiego 43, 07-415 Olszewo-Borki</li>
          <li>telefonu - pod numerem: 508 199 662</li>
        </ol>
      </li>
      <li>Sprzedawca dokłada wszelkich starań, aby zapewnić wszelkie środki fizycznej, technicznej i organizacyjnej ochrony danych osobowych przed nieuprawnionym ujawnieniem, usunięciem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem obowiązującego prawa, zmianą, utratą, uszkodzeniem lub zniszczeniem, zgodnie ze wszystkimi obowiązującymi przepisami.</li>
      <li>Sprzedawca stosuje środki techniczne i organizacyjne w celu zapewnienia ochrony danych osobowych adekwatnych do zagrożeń i kategorii chronionych danych.</li>
      <li>Sprzedawca przetwarza dane osobowe wszystkich Użytkowników w związku z wyrażeniem zgody na świadczenie usług (podstawą prawną przetwarzania jest art. 6 ust. 1 lit. a RODO).</li>
      <li>Dane osobowe Użytkowników będą również przetwarzane w celu ustalenia, obrony i dochodzenia roszczeń przez czas trwania korzystania z usług tj. do czasu cofnięcia zgody, poprzez skontaktowanie się ze Sprzedawcą za pomocą środków komunikacji wskazanych w § 1 ust. 4) Regulaminu, w granicach prawnie uzasadnionego interesu Sprzedawcy (podstawą prawną przetwarzania jest art. 6 ust. 1 litera f RODO).</li>
      <li>Sprzedawca przetwarza dane w celach dowodowych (wyrażenie zgody lub jej cofnięcie czy wątpliwości związane z prawidłową realizacją żądania w zakresie RODO).</li>
      <li>Dane osobowe Użytkowników będą udostępniane innym odbiorcom danych tylko i wyłącznie w przypadkach przewidzianych przepisami prawa (jeśli zajdzie taka potrzeba) lub na podstawie umów powierzenia przetwarzania danych osobowych podmiotom współpracującym ze Sprzedawcą. Odbiorcy będą przetwarzać dane za zgodą i na wyraźne polecenie Sprzedawcy.</li>
      <li>Przekazane dane osobowe będą przechowywane aż do czasu wycofania zgody (poprzez skontaktowanie się ze Sprzedawcą za pomocą środków komunikacji wskazanych w § 1 ust. 4) Regulaminu oraz do czasu przedawnienia roszczeń z tego tytułu. Po tych okresach dane będą przechowywane jedynie przez czas wymagany przepisami prawa.</li>
      <li>Sprzedawca zapewnia Użytkowników, których dane osobowe przetwarza, że posiadają oni uprawnienia wynikające z przepisów prawa, w tym prawo dostępu do treści własnych danych osobowych, ich sprostowania, zmiany, usunięcia lub przenoszenia, a ponadto prawo do żądania ograniczenia przetwarzania danych osobowych oraz do wniesienia sprzeciwu wobec takiego przetwarzania na zasadach określonych właściwymi przepisami.</li>
      <li>Każdej osobie, której dane są przetwarzane przysługuje prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa, jeśli uważa, że jego dane osobowe są przetwarzane niezgodnie z prawem.</li>
    </ol>
  </section>

  <header>
    <strong>§ 15</strong>
    <strong>Prawa i obowiązki Sprzedawcy oraz Użytkownika</strong>
  </header>

  <section>
    <ol>
      <li>Sprzedawca zobowiązuje się świadczyć wszelkie usługi dostępne za pośrednictwem Sklepu z należytą starannością, zgodnie z posiadaną wiedzą, możliwościami technicznymi oraz z postanowieniami Regulaminu i Polityki Prywatności.</li>
      <li>Korzystając z Usług Użytkownik zobowiązuje się do podejmowania działań zgodnych z obowiązującym prawem, zasadami współżycia społecznego i dobrymi obyczajami.</li>
      <li>Użytkownik jest zobowiązany zapewnić przestrzeganie postanowień Regulaminu i Polityki Prywatności jak również zobowiązany jest do poszanowania praw autorskich oraz praw własności intelektualnej Sprzedawcy oraz osób trzecich.</li>
      <li>Użytkownik zobowiązuje się – przy korzystaniu z usług – do podawania danych zgodnych ze stanem faktycznym i prawnym.</li>
      <li>Użytkownik zobowiązuje się do niezwłocznego aktualizowania swoich danych podanych podczas korzystania z usług w każdym przypadku ich zmiany. Jeżeli Użytkownik nie wypełni tego obowiązku lub kiedy dopuści się jego nienależytego wykonania, wszystkie oświadczenia, faktury lub informacje wysyłane do Użytkownika przez Sprzedawcę na dotychczasowo podane dane, w tym adresy elektroniczne, będą uważane za skutecznie doręczone.</li>
      <li>
        Użytkownik zobowiązuje się do wykorzystywania wszystkich materiałów przekazanych lub udostępnionych mu przez Sprzedawcę wyłącznie w zakresie własnego użytku osobistego (co dotyczy materiałów i treści przekazanych lub udostępnionych w formie pisemnej, elektronicznej lub ustnej), na następujących polach eksploatacji:
        <ol>
          <li>wydruk na własne potrzeby korzystania z materiałów,</li>
          <li>zapis metodą cyfrową, np. na własnym dysku twardym,</li>
          <li>kopiowanie, powielanie na własne potrzeby.</li>
        </ol>
      </li>
      <li>7) Modyfikowanie, kopiowanie, przesyłanie, publiczne odtwarzanie oraz wszelkie wykorzystywanie tych treści do innych celów, a w szczególności do celów komercyjnych, wymaga wyrażenia uprzedniej, pisemnej zgody przez Sprzedawcę lub przez inny uprawniony podmiot.</li>
    </ol>
  </section>

  <header>
    <strong>§ 16</strong>
    <strong>Odpowiedzialność Sprzedawcy</strong>
  </header>

  <section>
    <ol>
      <li>Sprzedawca zastrzega, że treści zawarte w materiałach przekazanych lub udostępnionych Użytkownikowi w związku z korzystaniem ze Sklepu i świadczonych za jej pośrednictwem usług mają charakter edukacyjny oraz informacyjny i nie mogą być wykorzystywane jako narzędzie służące podejmowaniu jakichkolwiek decyzji.</li>
      <li>
        Sprzedawca nie ponosi odpowiedzialności za jakiekolwiek szkody Użytkownika powstałe wskutek:
        <ol>
          <li>korzystania z materiałów określonych w § 16 ust. 1) Regulaminu w sposób niezgodny z prawem, Regulaminem i Polityką Prywatności,</li>
          <li>zablokowania dostępu Użytkownika do jakichkolwiek usług z przyczyn nie leżących po stronie Sprzedawcy, w tym również ze względu na naruszenie przepisów prawa, Regulaminu i Polityki Prywatności,</li>
          <li>wykorzystania przez Użytkownika danych oraz informacji udostępnianych w ramach świadczenia usług w celach gospodarczych, inwestycyjnych, biznesowych, itp.</li>
        </ol>
      </li>
      <li>Sprzedawca nie ponosi odpowiedzialności za jakość przekazu i odbioru usług świadczonych za pośrednictwem Sklepu, uzależnioną od przepustowości sieci internetowej, indywidualnej konfiguracji komputera Użytkownika oraz innych czynników od Sprzedawcy niezależnych.</li>
      <li>Sprzedawca nie ponosi odpowiedzialności za zachowanie osób trzecich stanowiące naruszenie postanowień Regulaminu i Polityki Prywatności.</li>
    </ol>
  </section>

  <header>
    <strong>§ 17</strong>
    <strong>Pozasądowe rozwiązywanie sporów</strong>
  </header>

  <section>
    <ol>
      <li>Postanowienia niniejszego § 17 dotyczą wyłącznie Klientów będących Konsumentami.</li>
      <li>Klient ma możliwość skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń.</li>
      <li>
        Szczegółowe informacje dotyczące możliwości skorzystania przez Klienta z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach internetowych:
        <ol>
          <li>powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów;</li>
          <li>Wojewódzkich Inspektoratów Inspekcji Handlowej;</li>
          <li>Urzędu Ochrony Konkurencji i Konsumentów.</li>
        </ol>
      </li>
      <li>Klient może skorzystać także z platformy do internetowego rozstrzygania sporów (ODR) dostępnej pod adresem: http://ec.europa.eu/consumers/odr/.</li>
    </ol>
  </section>

  <header>
    <strong>§ 18</strong>
    <strong>Zmiana Regulaminu</strong>
  </header>

  <section>
    <ol>
      <li>
        Sprzedawca może dokonać zmiany w Regulaminie w przypadku:
        <ol>
          <li>zmiany przedmiotu działalności Sprzedawcy;</li>
          <li>dokonania technicznej modyfikacji Sklepu wymagających dostosowania do nich postanowień Regulaminu;</li>
          <li>prawnego obowiązku dokonania zmian, w tym obowiązku dostosowania Regulaminu do aktualnego stanu prawnego. </li>
        </ol>
      </li>
      <li>O zmianie Regulaminu Klienci zostaną poinformowani poprzez opublikowanie jego zmienionej wersji na stronie internetowej Sklepu, co najmniej 14 dni przed dniem wejścia zmian w życie. W terminie, o którym mowa w zdaniu poprzedzającym, zmieniona wersja Regulaminu zostanie przesłana Użytkownikom pocztą elektroniczną.</li>
      <li>Do Umów sprzedaży zawartych przed dniem wejścia w życie nowego Regulaminu, stosuje się postanowienia ówcześnie obowiązującego Regulaminu.</li>
      <li>Użytkownik, który nie zgadza się na zmianę Regulaminu, może poprzez usunięcie Konta wypowiedzieć Umowę o świadczenie Usługi Konta ze skutkiem natychmiastowym do czasu wejścia w życie zmian Regulaminu. Brak wypowiedzenia uznaje się za zgodę na świadczenie przez Sprzedawcę usług drogą elektroniczną zgodnie ze zmienionymi postanowieniami Regulaminu.</li>
    </ol>
  </section>

  <header>
    <strong>§ 19</strong>
    <strong>Postanowienia końcowe</strong>
  </header>

  <section>
    <ol>
      <li>
        Częścią Regulaminu są następujące załączniki:
        <ol>
          <li>Załącznik nr 1 - Oświadczenie o odstąpieniu od Umowy Sprzedaży;</li>
          <li>Załącznik nr 2 - Oświadczenie Reklamacyjne.</li>
        </ol>
      </li>
      <li>Aktualna wersja Regulaminu obowiązuje od dnia 22.12.2023 r.</li>
    </ol>
  </section>
</article>
