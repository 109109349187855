export const errorCodesDict: Record<string, string> = {
  'auth/account-exists-with-different-credential': `To konto już istnieje - czy logowałeś się wcześniej używając konta Google? Jeżeli tak,
  użyj tej metody logowania. Jeżeli błąd będzie się powtarzał, skontaktuj się z nami`,
  'auth/popup-closed-by-user': `Zamknąłeś okno logowania, nie mogliśmy Cię zalogować. Spróbuj ponownie.`,
  'auth/requires-recent-login': `Musimy potwierdzić, że to na pewno Ty. Wyloguj się z aplikacji i zaloguj ponownie, aby kontynuować.`,
  'auth/user-not-found': `Nie znaleziono użytkownika z podanym adresem email, czy na pewno masz założone konto?`,
  'auth/email-already-in-use': `Istnieje już konto zarejestrowane pod tym adresem email. Spróbuj się zalogować lub skontaktuj się z nami.`,
}

export const defaultAuthErrorMessage = 'Wystąpił błąd podczas logowania, spróbuj ponownie lub skontaktuj się z nami.'
export const defaultAccountDeleteMessage = 'Wystąpił błąd podczas usuwania konta, spróbuj ponownie lub skontaktuj się z nami.'
