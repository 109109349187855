import {Component, OnInit} from '@angular/core';
import {slideInAnimation} from './animations/slide-in.animation';
import {RouterOutlet} from '@angular/router';
import {ShoppingFacade} from './shopping';
import {CartProduct} from './model/cart';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthFacade} from './auth/state/auth.facade';
import {MatDialog} from '@angular/material/dialog';
import {CookiesDialogComponent} from './shared/component/cookies-dialog/cookies-dialog.component';
import {CookieManager} from './shared/cookie-manager';
import {ContactComponent} from './shared/component/contact/contact.component';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {fromPromise} from 'rxjs/internal-compatibility';
import {filter, first, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {fromEvent} from 'rxjs';
import {DeliveryData} from './model/delivery-data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit {
  productsFromCart$ = this.shoppingFacade.productsFromCart$;
  totalSum$ = this.shoppingFacade.totalSum$;
  user$ = this.authFacade.userWithoutVerification$;

  title = 'babilas-web';

  emailVerificationUpdate$ = this.afAuth.user.pipe(
    filter((user) => !!user),
    first(),
    withLatestFrom(this.authFacade.userWithoutVerification$),
    filter(([, savedUser]) => savedUser?.emailVerified === false),
    switchMap(([, savedUser]) => this.afAuth.user.pipe(
      first(),
      switchMap((user) => fromPromise(user!.reload()).pipe(
        switchMap(() => fromPromise(this.afAuth.currentUser).pipe(
          tap((currentUser) => {
            if (currentUser?.emailVerified) {
              this.authFacade.updateUser({
                ...savedUser!,
                uid: currentUser.uid ?? '',
                email: currentUser.email ?? '',
                isAnonymous: currentUser.isAnonymous ?? true,
                emailVerified: currentUser.emailVerified,
              });
              this.shoppingFacade.updateDeliveryData({email: currentUser.email} as DeliveryData)
            }
          })
        ))
      ))
    ))
  )

  updateEmailVerificationOnTabChange$ = fromEvent(window, 'focus').pipe(
    switchMap(() => this.emailVerificationUpdate$)
  )

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  constructor(private shoppingFacade: ShoppingFacade,
              private authFacade: AuthFacade,
              private matIconRegistry: MatIconRegistry,
              private matDialog: MatDialog,
              private domSanitizer: DomSanitizer,
              private afAuth: AngularFireAuth) {
    matIconRegistry.addSvgIcon('google', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/google.svg'));
    // Facebook icon by Font Awesome - https://fontawesome.com/license
    matIconRegistry.addSvgIcon('facebook', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook.svg'));
    matIconRegistry.addSvgIcon('package-machine', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/package-machine.svg'));
    matIconRegistry.addSvgIcon('courier', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/courier.svg'));
  }

  ngOnInit() {
    if (CookieManager.noConsentsGiven) {
      this.openCookiesDialog();
    }

    this.emailVerificationUpdate$.subscribe();
    this.updateEmailVerificationOnTabChange$.subscribe();
  }

  changeProductQuantity(cartProduct: CartProduct) {
    this.shoppingFacade.addProductToCart(cartProduct);
  }

  removeProductFromCart(productId: string) {
    this.shoppingFacade.removeProductFromCart(productId);
  }

  openCookiesDialog() {
    this.matDialog.open(CookiesDialogComponent, {disableClose: true, panelClass: 'app-cookies-dialog', autoFocus: false});
  }
}
