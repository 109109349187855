import {Component, HostListener, Input, OnInit} from '@angular/core';
import {fadeInAnimation} from '../../animations/fade-in.animation';
import {FormBuilder} from '@angular/forms';
import {Location} from '@angular/common';
import {User} from '../../model/user';
import {MatDialog} from '@angular/material/dialog';
import {ContactComponent} from '../component/contact/contact.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInAnimation],
})
export class HeaderComponent implements OnInit {
  @Input() user: Optional<User>;

  showCart = true;
  headerChanged = false;

  @HostListener('window:scroll') changeHeader() {
    this.headerChanged = document.documentElement.scrollTop > 40;
  }

  constructor(private fb: FormBuilder, private location: Location, private matDialog: MatDialog) {
    this.headerChanged = document.documentElement.scrollTop > 40;
  }

  ngOnInit() {
    this.location.onUrlChange((url) => this.showCart = !url.includes('checkout'));
  }

  get userName() {
    return this.user?.displayName || this.user?.deliveryData?.firstName;
  }

  openContactDialog() {
    this.matDialog.open(ContactComponent, {autoFocus: false});
  }
}
