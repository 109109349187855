import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {AuthState} from './auth.reducer';
import {authActions} from './auth.actions';
import {authSelectors} from './auth.selectors';
import {filter, first, map, startWith, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {Util} from '../../common/util';
import {Observable} from 'rxjs';
import {UserOrder} from '../../model/order';
import {OrderStatus} from '../../model/order-status';
import {User} from '../../model/user';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root'
})
export class AuthFacade {
  userWithoutVerification$ = this.store.pipe(select(authSelectors.user));
  user$ = this.userWithoutVerification$.pipe(
    map((user) => user?.emailVerified ? user : null)
  );
  presentUser$ = this.user$.pipe(filter(Util.isNonNull));
  deliveryData$ = this.store.pipe(select(authSelectors.deliveryData));
  orders$ = this.store.pipe(
    select(authSelectors.orders),
    filter(Util.isNonNull),
  );
  lastOrder$ = this.orders$.pipe(
    map((orders) => orders[orders.length - 1]),
  )
  lastOrderCart$ = this.lastOrder$.pipe(
    map((lastOrder) => lastOrder.cart)
  )
  allUsers$ = this.store.pipe(select(authSelectors.allUsers));
  allOrders$: Observable<UserOrder[]> = this.allUsers$.pipe(
    map((allUsers) => allUsers
      ?.reduce((result, user) => [...result, ...(user.orders ?? []).map((order) => ({...order, userId: user.uid}))], [] as UserOrder[])),
    filter(Util.isNonNull)
  );

  allOrdersByStatus$ = this.allOrders$.pipe(
    map((allOrders) => Object.values(OrderStatus)
      .reduce((result, orderStatus) =>
        [...result, allOrders?.filter(order => order.status === orderStatus)], [] as UserOrder[][])
    ),
  )

  constructor(private store: Store<AuthState>) {
  }

  signInWithFacebook() {
    return this.store.dispatch(authActions.signInWithFacebook());
  }

  signInWithGoogle() {
    return this.store.dispatch(authActions.signInWithGoogle());
  }

  signInWithEmailAndPassword(email: string, password: string) {
    return this.store.dispatch(authActions.signInWithEmailAndPassword({email, password}));
  }

  signUpWithEmailAndPassword(email: string, password: string) {
    return this.store.dispatch(authActions.signUpWithEmailAndPassword({email, password}));
  }

  sendPasswordResetEmail(email: string) {
    return this.store.dispatch(authActions.sendPasswordResetEmail({email}));
  }

  signInAnonymously() {
    return this.store.dispatch(authActions.signInAnonymously());
  }

  logout() {
    return this.store.dispatch(authActions.logout());
  }

  updateUser(user: Optional<User>) {
    return this.store.dispatch(authActions.updateUser({user}));
  }

  fetchAllUsers() {
    return this.store.dispatch(authActions.fetchAllUsers());
  }

  deleteAccount() {
    return this.store.dispatch(authActions.deleteAccount());
  }

  changeOrderStatus(userId: string, orderId: string) {
    return this.store.dispatch(authActions.changeOrderStatus({userId, orderId}));
  }
}
