import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {apiUrlToken} from '@@common';
import {User} from '../../model/user';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Observable} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AngularFireAuth} from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthDataService {
  constructor(private http: HttpClient,
              private firestore: AngularFirestore,
              private functions: AngularFireFunctions,
              private afAuth: AngularFireAuth,
              @Inject(apiUrlToken) private api: string) {
  }

  getUser(userId: string) {
    return this.afAuth.user.pipe(
      switchMap((authenticatedUser) => this.firestore.doc<User>(`users/${userId}`).valueChanges().pipe(
        filter(() => !!authenticatedUser)
      ))
    );
  }

  updateUser(user: any): Observable<void> {
    return this.afAuth.user.pipe(
      filter((user) => !!user),
      switchMap(() => fromPromise(this.firestore.doc<User>(`users/${user.uid}`)
        .set({
          uid: user.uid,
          email: user.email,
          isAnonymous: user.isAnonymous,
          emailVerified: user.emailVerified,
        }, {merge: true})))
    );
  }

  fetchAllUsers() {
    return this.firestore.collection<User>(`users`).valueChanges();
  }

  changeOrderStatus(userId: string, orderId: string) {
    return this.functions.httpsCallable('changeOrderStatus')({userId, orderId})
  }
}
