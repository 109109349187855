<ng-container *ngIf="products?.length">
  <div *ngFor="let product of products"
       class="cart-product">
    <img [src]="product.images && product.images[0]?.small"
         [alt]="product.name">
    <div *ngIf="!readonly">
      <a [routerLink]="['/', 'product', product.id]">{{ product.name }}</a>
      <div class="single-price"><small>{{ (product?.quantity ?? 1) * (product?.price ?? 0) | currency : 'PLN' }}</small>
      </div>
    </div>

    <div *ngIf="readonly">
      <small>{{ product.name }}</small>
      <div class="single-price"><small>{{ (product?.quantity ?? 1) * (product?.price ?? 0) | currency : 'PLN' }}</small>
      </div>
    </div>

    <app-add-product *ngIf="!readonly"
                     [cartProduct]="{id: product.id, quantity: product?.quantity ?? 0}"
                     [noButton]="true"
                     (productRemove)="productRemove.emit($event)"
                     (productAdd)="quantityChange.emit($event)"></app-add-product>
    <div *ngIf="readonly"><small>Ilość: {{ product?.quantity }}</small></div>

  </div>
  <footer>
    <div>
      <p *ngIf="selectedDelivery?.price">Dostawa: {{ (selectedDelivery?.price || 0) | currency : 'PLN' : 'symbol' }}</p>
      <p>Łącznie:
        <strong>
          {{ ((totalSum ?? 0) + (selectedDelivery?.price ?? 0)) | currency : 'PLN' : 'symbol' }}
        </strong>
      </p>
      <p *ngIf="selectedDeliveryOutOfTotalSum?.price">W tym dostawa: {{ (selectedDeliveryOutOfTotalSum?.price || 0) | currency : 'PLN' : 'symbol' }}</p>
    </div>
    <a *ngIf="!checkingOut && !readonly"
       [routerLink]="['/', 'checkout']">
      <button mat-button
              class="button-primary button-big"
              (click)="checkout.emit()">Złóż zamówienie
      </button>
    </a>
  </footer>
</ng-container>
