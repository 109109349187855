import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {CartProduct} from '../../../model/cart';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent {
  addProductDebouncer = new Subject<CartProduct>();
  formControl = new FormControl(null);

  private _cartProduct: Optional<CartProduct>;
  @Input() set cartProduct(cartProduct: Optional<CartProduct>) {
    this._cartProduct = cartProduct;
    this.formControl.patchValue(cartProduct?.quantity);
  };
  get cartProduct() {
    return this._cartProduct;
  }

  @Input() noButton = false;

  @Output() productAdd = new EventEmitter<CartProduct>();
  @Output() productRemove = new EventEmitter<string>();

  constructor(private cd: ChangeDetectorRef) {
    this.addProductDebouncer.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe((cartProduct) => this.productAdd.emit(cartProduct));
  }

  addProductDebounced(value: any) {
    if (Number.isInteger(value.quantity)) {
      this.addProductDebouncer.next(value);
    }
  }
}
