import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CartGuard} from './shopping/guard/cart.guard';
import {AuthGuard} from './auth/guard/auth.guard';
import {FaqComponent} from './shared/component/faq/faq.component';
import { PrivacyPolicyComponent } from './shared/component/privacy-policy/privacy-policy.component';
import {RulesComponent} from './shared/component/rules/rules.component';
import {ContactComponent} from './shared/component/contact/contact.component';
import {ReturnsComponent} from './shared/component/returns/returns.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard, CartGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home', loadChildren: () =>
          import('./views/home/home-page.module').then((m) => m.HomePageModule)
      },
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'contact',
        component: ContactComponent,
      },
      {
        path: 'rules',
        component: RulesComponent,
      },
      {
        path: 'returns',
        component: ReturnsComponent,
      },
      {
        path: 'admin-manage', loadChildren: () =>
          import('./views/admin/admin-page.module').then((m) => m.AdminPageModule),
      },
      {
        path: 'product', loadChildren: () =>
          import('./views/product/product-page.module').then((m) => m.ProductPageModule)
      },
      {
        path: 'checkout', loadChildren: () =>
          import('./views/checkout/checkout-page.module').then((m) => m.CheckoutPageModule)
      },
      {
        path: 'confirmation', loadChildren: () =>
          import('./views/confirmation/confirmation-page.module').then((m) => m.ConfirmationPageModule)
      },
      {
        path: 'account', loadChildren: () =>
          import('./views/account/account-page.module').then((m) => m.AccountPageModule)
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'home',
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
