import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ShoppingFacade} from '../state/shopping.facade';
import {ShoppingDataService} from '../service/shopping.data.service';

@Injectable({
  providedIn: 'root'
})
export class CartGuard implements CanActivateChild {
  constructor(private facade: ShoppingFacade,
              private dataService: ShoppingDataService) {
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const cartId = localStorage.getItem('babilas_cart_id');

    if (!cartId) {
      this.facade.createCart();
    } else {
      this.facade.getCart(cartId);
    }

    return true;
  }
}
