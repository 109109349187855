import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Product} from '../../../model/product';
import {CartProduct} from '../../../model/cart';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PricedDelivery} from '../../../model/delivery';

@Component({
  selector: 'app-cart-content',
  templateUrl: './cart-content.component.html',
  styleUrls: ['./cart-content.component.scss']
})
export class CartContentComponent {
  formGroup: Optional<FormGroup>;

  private _products: Optional<Product[]>
  @Input() set products(products: Optional<Product[]>) {
    this._products = products;
    this.formGroup = this.fb.group(products?.reduce((result, product) => ({
      ...result,
      [product.id]: this.fb.control(product.quantity ?? 0)
    }), {}) ?? {});
  };

  get products() {
    return this._products;
  }

  @Input() totalSum: Optional<number>;
  @Input() checkingOut: Optional<boolean>;
  @Input() readonly: Optional<boolean>;
  @Input() selectedDelivery: Optional<PricedDelivery>;
  @Input() selectedDeliveryOutOfTotalSum: Optional<PricedDelivery>;

  @Output() quantityChange = new EventEmitter<CartProduct>();
  @Output() productRemove = new EventEmitter<string>();
  @Output() checkout = new EventEmitter<void>();

  constructor(private fb: FormBuilder) {
  }
}
