import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, rootEffectsInit} from '@ngrx/effects';
import {catchError, exhaustMap, filter, map, mapTo, switchMap, tap} from 'rxjs/operators';
import {authActions} from './auth.actions';
import {AuthDataService} from '../service/auth.data.service';
import {EMPTY, from, MonoTypeOperatorFunction, of, pipe, throwError} from 'rxjs';
import {fromPromise} from 'rxjs/internal-compatibility';
import {User} from '../../model/user';
import {MatSnackBar} from '@angular/material/snack-bar';
import {defaultAccountDeleteMessage, defaultAuthErrorMessage, errorCodesDict} from '../../common/error-codes';
import {MatDialog} from '@angular/material/dialog';
import {SimpleDialogComponent} from '../../shared/component/simple-dialog/simple-dialog.component';
import {Util} from '../../common/util';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {GoogleAuthProvider, FacebookAuthProvider, deleteUser, getAuth, reauthenticateWithPopup} from '@angular/fire/auth'
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthEffects {
  private deleteAccountMessage = `Usunięcie konta może wydłużyć czas realizacji zamówienia.
  Zalecamy usunięcie konta dopiero wtedy, gdy wszystkie zamówienia zostaną zrealizowane.
  Czy chcesz kontynuować?`;
  readonly auth$ = createEffect(() => this.actions$.pipe(
    ofType(rootEffectsInit),
    switchMap(() => this.afAuth.authState.pipe(
      switchMap((user) => user ? this.dataService.getUser(user?.uid) : of(null)),
      map((user) => authActions.saveUser({user})),
    )),
  ))

  readonly updateUser$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.updateUser),
    switchMap(({user}) => this.dataService.updateUser(user).pipe(
      switchMap(() => this.dataService.getUser(user?.uid as string).pipe(
        map((u) => authActions.saveUser({user: u})),
      ))
    )),
  ))

  readonly changeOrderStatus$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.changeOrderStatus),
    switchMap(({userId, orderId}) => this.dataService.changeOrderStatus(userId, orderId)),
  ), {dispatch: false});

  readonly logout$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.logout),
    switchMap(() => fromPromise(this.afAuth.signOut()).pipe(
      tap(() => this.matSnackBar.open('Pomyślnie wylogowano z aplikacji')),
      map(() => authActions.saveUser({user: null})),
    )),
  ),)


  readonly fetchAllUsers$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.fetchAllUsers),
    switchMap(() => this.dataService.fetchAllUsers()),
    map((users) => authActions.saveAllUsers({users})),
  ))


  readonly deleteAccount$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.deleteAccount),
    exhaustMap(() => this.matDialog.open(SimpleDialogComponent, {data: {message: this.deleteAccountMessage}}).afterClosed().pipe(
      filter((decision) => !!decision),
      switchMap(() => {
        const currentUser = getAuth().currentUser;
        return currentUser
          ? fromPromise(currentUser.delete())
            .pipe(
              tap(() => this.router.navigate(['/'])),
              exhaustMap(() => this.matSnackBar.open('Konto zostało usunięte.').afterOpened()),
              catchError((e) => {
                this.matSnackBar.open(errorCodesDict[e.code] ?? defaultAccountDeleteMessage);
                return throwError('ERR');
              })
            )
          : throwError('No user to delete');
      }),
    )),
    map(() => authActions.saveUser({user: null})),
  ))

  readonly signInAnonymously$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.signInAnonymously),
    switchMap((provider) => fromPromise(this.afAuth.signInAnonymously())),
    this.checkAuthError(),
    map((userData) => ({
      uid: userData.user?.uid ?? '',
      email: userData.user?.email ?? '',
      isAnonymous: userData.user?.isAnonymous ?? true,
      emailVerified: true,
    })),
    map((user: User) => authActions.updateUser({user})),
  ))

  readonly signInWithGoogle$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.signInWithGoogle),
    map(() => new GoogleAuthProvider()),
    switchMap((provider) => fromPromise(this.afAuth.signInWithRedirect(provider))),
    this.checkAuthError(),
  ), {dispatch: false})

  readonly signInWithFacebook$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.signInWithFacebook),
    map(() => new FacebookAuthProvider()),
    switchMap((provider) => fromPromise(this.afAuth.signInWithRedirect(provider))),
    this.checkAuthError(),
  ), {dispatch: false})


  readonly signInWithEmailAndPassword$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.signInWithEmailAndPassword),
    switchMap(({email, password}) => fromPromise(this.afAuth.signInWithEmailAndPassword(email, password)).pipe(
      this.checkAuthError(),
      map(() => authActions.getUser()),
    )),
  ))

  readonly signUpWithEmailAndPassword$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.signUpWithEmailAndPassword),
    switchMap(({email, password}) => fromPromise(this.afAuth.createUserWithEmailAndPassword(email, password)).pipe(
      this.checkAuthError(),
      switchMap((userData) => fromPromise(userData.user!.sendEmailVerification()).pipe(
        map(() => authActions.getUser()),
      )),
    )),
  ))

  readonly sendPasswordResetEmail$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.sendPasswordResetEmail),
    switchMap(({email}) => fromPromise(this.afAuth.sendPasswordResetEmail(email))),
    this.checkAuthError(),
  ), {dispatch: false})


  constructor(private actions$: Actions,
              private dataService: AuthDataService,
              private matSnackBar: MatSnackBar,
              private matDialog: MatDialog,
              private router: Router,
              private afAuth: AngularFireAuth) {
  }

  private checkAuthError<T>(): MonoTypeOperatorFunction<T> {
    return pipe(
      catchError((error) => {
        return this.matSnackBar
          .open(errorCodesDict[error.code] ?? defaultAuthErrorMessage, undefined, {duration: 10000, panelClass: 'app-snackbar-error'})
          .afterOpened().pipe(mapTo(null));
      }),
      filter(Util.isNonNull),
    )
  }

  private resolveReauthentication() {
    const provider = getAuth().currentUser?.providerData[0];
    const googleProvider = new GoogleAuthProvider();
    const facebookProvider = new FacebookAuthProvider();
  }
}
