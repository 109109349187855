<header class="floating-header">
  <h3 *ngIf="headerChanged"
      [routerLink]="['/', 'home']"
      @fadeIn><small>empatai</small></h3>
  <span *ngIf="!headerChanged"
        @fadeIn>Cześć<span *ngIf="userName"
                           class="name"> {{ userName }}</span>, miło Cię widzieć!</span>
  <div @fadeIn>
    <button mat-icon-button
            (click)="openContactDialog()"
            matTooltip="Masz pytania? Kliknij, aby nam je zadać!">
      <mat-icon>mail</mat-icon>
    </button>
    <a [routerLink]="['/', 'account']">
      <button mat-button
              mat-icon-button
              matTooltip="Przejdź do szczegółów Twojego konta">
        <mat-icon>account_circle</mat-icon>
      </button>
    </a>
    <ng-content *ngIf="showCart"
                select="[cart]"></ng-content>
  </div>
</header>

<header class="main-header">
  <h1 class="primary-dark"
      [routerLink]="['/', 'home']">
    <span>empatai</span>
  </h1>

  <div>
    <a [routerLink]="['/', 'faq']">Najczęstsze pytania</a>
    <a [routerLink]="['/', 'privacy-policy']">Polityka prywatności</a>
    <a [routerLink]="['/', 'rules']">Regulamin</a>
    <a [routerLink]="['/', 'returns']">Zwroty</a>
  </div>
</header>
