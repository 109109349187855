import {Action, createReducer, on} from '@ngrx/store';
import {Category} from '../../model/category';
import {shoppingActions} from './shopping.actions';
import {Product} from '../../model/product';
import {Cart} from '../../model/cart';
import {CheckoutType} from '../../model/checkout-type';
import {DeliveryType} from '../../model/delivery-type';
import {PackageMachine} from '../../model/package-machine';
import {PaymentIntent} from '@stripe/stripe-js';
import {PaymentType} from '../../model/payment-type';
import {Delivery} from '../../model/delivery';
import {CheckoutStep} from '../../model/checkout-step';
import {authActions} from '../../auth/state/auth.actions';
import {CookieManager} from '../../shared/cookie-manager';

export const SHOPPING_FEATURE_KEY = 'shopping';

export interface ShoppingState {
  categories: Category[];
  products: Product[];
  cart: Optional<Cart>;
  checkoutType: CheckoutType;
  paymentType: PaymentType;
  deliveryType: DeliveryType;
  paymentIntent: Optional<PaymentIntent>;
  selectedPackageMachine: Optional<PackageMachine>;
  checkoutStep: Optional<CheckoutStep>;
  deliveries: Delivery[];
  nextStepLoading: boolean;
}

export const initialState: ShoppingState = {
  categories: [],
  products: [],
  deliveries: [],
  cart: null,
  checkoutType: CheckoutType.Registration,
  deliveryType: DeliveryType.Courier,
  paymentType: PaymentType.Online,
  selectedPackageMachine: null,
  checkoutStep: 0,
  paymentIntent: null,
  nextStepLoading: false,
};

const reducer = createReducer(
  initialState,
  on(shoppingActions.saveCategories, (state, {categories}) => {
    return ({...state, categories});
  }),
  on(shoppingActions.saveProducts, (state, {products}) => {
    return ({...state, products});
  }),
  on(shoppingActions.saveDeliveries, (state, {deliveries}) => {
    return ({...state, deliveries});
  }),
  on(shoppingActions.saveCart, (state, {cart}) => {
    return ({...state, cart});
  }),
  on(shoppingActions.goToSummary, (state) => {
    return ({...state, paymentIntent: null, nextStepLoading: true});
  }),
  on(shoppingActions.changeCheckoutType, (state, {checkoutType}) => {
    return ({...state, checkoutType});
  }),
  on(shoppingActions.changeDeliveryType, (state, {deliveryType}) => {
    return ({...state, deliveryType});
  }),
  on(shoppingActions.selectPackageMachine, (state, {packageMachine}) => {
    return ({...state, selectedPackageMachine: packageMachine});
  }),
  on(shoppingActions.changeCheckoutStep, (state, {step}) => {
    return ({...state, checkoutStep: step, nextStepLoading: false});
  }),
  on(shoppingActions.savePaymentIntent, (state, {paymentIntent}) => {
    return ({...state, paymentIntent, checkoutStep: CheckoutStep.Summary, nextStepLoading: false});
  }),
  on(shoppingActions.changePaymentType, (state, {paymentType}) => {
    return ({...state, paymentType});
  }),
  on(shoppingActions.confirmOrder, (state) => {
    return ({...state, nextStepLoading: true});
  }),
  on(authActions.saveUser, (state, {user}) => {
    return ({
      ...state,
      deliveryType: user?.deliveryData?.deliveryType ?? state.deliveryType,
      paymentType: user?.deliveryData?.paymentType ?? state.paymentType,
      selectedPackageMachine: user?.deliveryData?.packageMachine ?? state.selectedPackageMachine,
    });
  }),
);

export function shoppingReducer(
  state: ShoppingState | undefined,
  action: Action
): ShoppingState {
  return reducer(state, action);
}
