import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ContactComponent} from '../contact/contact.component';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent {
  constructor(private matDialog: MatDialog) {
  }


  openContactDialog() {
    this.matDialog.open(ContactComponent, {autoFocus: false});
  }
}
