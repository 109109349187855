import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import localePl from '@angular/common/locales/pl';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {MatToolbarModule} from '@angular/material/toolbar';
import {StateModule} from './state.module';
import {HttpClientModule} from '@angular/common/http';
import {apiUrlToken} from '@@common';
import {registerLocaleData} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {ShoppingModule} from './shopping';
import {AuthModule} from './auth/auth.module';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {REGION} from '@angular/fire/compat/functions';
import {NgxMaskModule} from 'ngx-mask';

declare global {
  interface Window { easyPackAsyncInit: any; }
}

registerLocaleData(localePl);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    HttpClientModule,
    StateModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    MatToolbarModule,
    ShoppingModule,
    AuthModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pl-PL'},
    {provide: apiUrlToken, useValue: 'http://localhost:1337'},
    { provide: REGION, useValue: 'europe-west1' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
