import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CartProduct} from '../../../model/cart';
import {Product} from '../../../model/product';
import {FormBuilder, FormGroup} from '@angular/forms';
import {fadeInAnimation} from '../../../animations/fade-in.animation';
import {CheckoutStep} from '../../../model/checkout-step';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  animations: [fadeInAnimation],
})
export class CartComponent {
  readonly CheckoutStep = CheckoutStep;

  formGroup: Optional<FormGroup>;
  timedOutCloser: any;
  mouseIn = false;

  private _products: Optional<Product[]>
  @Input() set products(products: Optional<Product[]>) {
    this._products = products;
    this.formGroup = this.fb.group(products?.reduce((result, product) => ({
      ...result,
      [product.id]: this.fb.control(product.quantity ?? 0)
    }), {}) ?? {});
  };
  get products() {
    return this._products;
  }

  @Input() totalSum: Optional<number>;
  @Input() checkoutStep: Optional<CheckoutStep>;

  @Output() quantityChange = new EventEmitter<CartProduct>();
  @Output() productRemove = new EventEmitter<string>();

  constructor(private fb: FormBuilder) {
  }

  mouseEnter() {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    this.mouseIn = true;
  }

  mouseLeave() {
    this.timedOutCloser = setTimeout(() => {
      this.mouseIn = false;
    }, 350);
  }

  get productsQuantity() {
    return this.products?.reduce((result, product) => (product?.quantity ?? 0) + result, 0);
  }

  close() {
    this.mouseIn = false;
  }
}
