import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {adminActions} from './admin.actions';
import {switchMap, tap} from 'rxjs/operators';
import {AdminDataService} from '../service/admin.data.service';

@Injectable({
  providedIn: 'root'
})
export class AdminEffects {

  readonly saveCategory$ = createEffect(() => this.actions$.pipe(
    ofType(adminActions.saveCategory),
    switchMap(({category}) => this.dataService.saveCategory(category)),
  ), {dispatch: false})

  readonly deleteCategory$ = createEffect(() => this.actions$.pipe(
    ofType(adminActions.deleteCategory),
    switchMap(({categoryId}) => this.dataService.deleteCategory(categoryId)),
  ), {dispatch: false})

  readonly deleteProduct$ = createEffect(() => this.actions$.pipe(
    ofType(adminActions.deleteProduct),
    switchMap(({productId}) => this.dataService.deleteProduct(productId)),
  ), {dispatch: false})

  readonly saveProduct$ = createEffect(() => this.actions$.pipe(
    ofType(adminActions.saveProduct),
    switchMap(({product}) => this.dataService.saveProduct(product)),
  ), {dispatch: false})

  readonly saveDelivery$ = createEffect(() => this.actions$.pipe(
    ofType(adminActions.saveDelivery),
    switchMap(({delivery}) => this.dataService.saveDelivery(delivery)),
  ), {dispatch: false})

  readonly deleteDelivery$ = createEffect(() => this.actions$.pipe(
    ofType(adminActions.deleteDelivery),
    switchMap(({deliveryId}) => this.dataService.deleteDelivery(deliveryId)),
  ), {dispatch: false})

  constructor(private actions$: Actions,
              private dataService: AdminDataService) {
  }
}
