import {createFeatureSelector, createSelector} from '@ngrx/store';
import {SHOPPING_FEATURE_KEY, ShoppingState} from './shopping.reducer';

const getShoppingState = createFeatureSelector<ShoppingState>(SHOPPING_FEATURE_KEY);

const categories = createSelector(getShoppingState, (state) => state.categories);
const products = createSelector(getShoppingState, (state) => state.products);
const cart = createSelector(getShoppingState, (state) => state.cart);
const checkoutType = createSelector(getShoppingState, (state) => state.checkoutType);
const deliveryType = createSelector(getShoppingState, (state) => state.deliveryType);
const paymentType = createSelector(getShoppingState, (state) => state.paymentType);
const selectedPackageMachine = createSelector(getShoppingState, (state) => state.selectedPackageMachine);
const checkoutStep = createSelector(getShoppingState, (state) => state.checkoutStep);
const paymentIntent = createSelector(getShoppingState, (state) => state.paymentIntent);
const deliveries = createSelector(getShoppingState, (state) => state.deliveries);
const nextStepLoading = createSelector(getShoppingState, (state) => state.nextStepLoading);

export const shoppingSelectors = {
  categories,
  products,
  deliveries,
  cart,
  checkoutType,
  deliveryType,
  selectedPackageMachine,
  checkoutStep,
  paymentIntent,
  paymentType,
  nextStepLoading,
};
