<header>
  <h3>Polityka prywatności sklepu "Empatai"</h3>
</header>

<article>
  <p>Niniejsza Polityka prywatności (dalej jako: „Polityka”) zawiera informacje na temat
     przetwarzania Twoich danych osobowych w związku z korzystaniem ze sklepu internetowego
     „Empatai” działającego pod adresem internetowym https://empatai.pl (dalej jako: „Sklep”).</p>

  <p>Wszelkie terminy pisane wielką literą, które nie zostały zdefiniowane inaczej w Polityce mają
     znaczenie nadane im w Regulaminie, dostępnym pod adresem: https://empatai.pl/rules</p>

  <p><strong>Administrator danych osobowych</strong></p>

  <p>Administratorem Twoich danych osobowych Aneta Mierzejewska, prowadząca jednoosobową
     działalność gospodarczą pod nazwą: „Pracownia Psychologiczna &quot;Równowaga&quot; dr Aneta
     Mierzejewska, z siedzibą przy ul. Władysława Broniewskiego 43, 07-415 Olszewo-Borki, wpisaną
     do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, posiadającą NIP:
     7582378822, REGON: 388521920 (dalej jako: „Administrator”).</p>

  <p><strong>Kontakt z Administratorem</strong></p>

  <p>We wszystkich sprawach związanych z przetwarzaniem danych osobowych możesz kontaktować
     się z Administratorem za pomocą:</p>

  <ol>
    <li>poczty elektronicznej - pod adresem: biuro@empatai.pl</li>
    <li>poczty tradycyjnej - pod adresem: ul. Władysława Broniewskiego 43, 07-415 Olszewo-Borki</li>
    <li>telefonu - pod numerem: 508 199 662</li>
  </ol>

  <p><strong>Środki ochrony danych osobowych</strong></p>

  <p>Administrator stosuje nowoczesne zabezpieczenia organizacyjne i techniczne, aby zapewnić jak
     najlepszą ochronę Twoich danych osobowych oraz gwarantuje, że przetwarza je zgodnie z
     przepisami Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
     2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w
     sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
     rozporządzenie o ochronie danych) (dalej: „RODO”), ustawy z dnia 10 maja 2018 r. o ochronie
     danych osobowych oraz innymi przepisami o ochronie danych osobowych.</p>

  <p><strong>Informacje o przetwarzanych danych osobowych</strong></p>

  <p>Korzystanie ze Sklepu wymaga przetwarzania Twoich danych osobowych. Poniżej znajdziesz
     szczegółowe informacje o celach i podstawach prawnych przetwarzania, a także o okresie
     przetwarzania oraz obowiązku lub dobrowolności ich podania.</p>
</article>


<p><strong>Cel przetwarzania:</strong> Zawarcie i wykonanie Umowy o świadczenie usługi Konta</p>
<p><strong>Przetwarzane dane osobowe:</strong>imię, nazwisko, adres poczty elektronicznej</p>
<p><strong>Podstawa prawna: </strong>art. 6 ust. 1 lit. b RODO (przetwarzanie jest niezbędne do wykonania Umowy o
                                     świadczenie Usługi Konta zawartej z osobą, której dotyczą dane, lub podjęcia
                                     działań w celu jej zawarcia)</p>
<p>Podanie ww. danych osobowych jest warunkiem zawarcia i wykonania umowy o świadczenie usługi Konta (ich podanie jest
   dobrowolne, lecz konsekwencją ich niepodania będzie niemożność zawarcia i wykonania ww. umowy, w tym utworzenie
   Konta).</p>
<p>Administrator będzie przetwarzał ww. dane osobowe do czasu przedawnienia roszczeń wynikających z umowy o świadczenie
   Usługi Konta.</p>

<p><strong>Cel przetwarzania:</strong> Zawarcie i wykonanie Umowy sprzedaży</p>
<p><strong>Przetwarzane dane osobowe:</strong>imię, nazwisko, numer telefonu, adres poczty elektronicznej, adres dostawy
                                              (ulica, numer domu, numer lokalu, miejscowość, kod pocztowy, kraj), sposób
                                              dostawy, sposób płatności, NIP,;</p>
<p><strong>Podstawa prawna: </strong>art. 6 ust. 1 lit. b RODO (przetwarzanie jest niezbędne do wykonania Umowy zawartej
                                     z osobą, której dotyczą dane, lub podjęcia działań w celu jej zawarcia)</p>
<p>Podanie ww. danych osobowych jest warunkiem zawarcia i wykonania Umowy sprzedaży, Umowy o dostarczanie treści
   cyfrowych lub Umowy mieszanej (ich podanie jest dobrowolne, lecz konsekwencją ich niepodania będzie niemożność
   zawarcia i wykonania ww. Umowy lub Umów).</p>
<p>Administrator będzie przetwarzał ww. dane osobowe do czasu przedawnienia roszczeń wynikających z ww. Umowy lub
   Umów.</p>

<p><strong>Cel przetwarzania:</strong> Przeprowadzenie postępowania reklamacyjnego</p>
<p><strong>Przetwarzane dane osobowe:</strong>imię, nazwisko, adres poczty elektronicznej, numer telefonu, adres do
                                              korespondencji (ulica, numer domu, numer lokalu, kod pocztowy,
                                              miejscowość, kraj);</p>
<p><strong>Podstawa prawna: </strong>art. 6 ust. 1 lit. c RODO (przetwarzanie jest niezbędne do wypełnienia obowiązku
                                     prawnego ciążącego na Administratorze, w tym przypadku obowiązków: udzielenia
                                     odpowiedzi na reklamację – art. 7a ustawy o prawach konsumenta; realizacji
                                     uprawnień Kupującego wynikających z rękojmi – art. 556 i n. Kodeksu cywilnego)</p>
<p>Podanie ww. danych osobowych jest warunkiem otrzymania odpowiedzi na reklamację lub realizacji uprawnień Kupującego
   wynikających z rękojmi (ich podanie jest dobrowolne, lecz konsekwencją ich niepodania będzie niemożność otrzymania
   odpowiedzi na reklamację oraz realizacji uprawnień wynikających z rękojmi).</p>
<p>Administrator będzie przetwarzał ww. dane osobowe przez czas postępowania reklamacyjnego (nie dłużej jednak, niż 14
   dni), a w wypadku realizacji uprawnień Konsumenta wynikających z rękojmi – do czasu ich przedawnienia.</p>

<p><strong>Cel przetwarzania:</strong> Przesyłanie powiadomień e-mail (np. informujących o postępach realizacji
                                       Zamówienia)</p>
<p><strong>Przetwarzane dane osobowe:</strong>imię, nazwisko, adres poczty elektronicznej</p>
<p><strong>Podstawa prawna: </strong>art. 6 ust. 1 lit. f RODO (przetwarzanie jest niezbędne w celu realizacji prawnie
                                     uzasadnionego interesu Administratora, w tym przypadku informowania Klientów o
                                     podejmowanych czynnościach związanych ze świadczeniem usług)</p>
<p>Podanie ww. danych osobowych dobrowolne, ale niezbędne w celu otrzymywania informacji o czynnościach związanych ze
   świadczeniem usług (konsekwencją ich niepodania będzie niemożność otrzymania ww. informacji).</p>
<p>Administrator będzie przetwarzał ww. dane osobowe do czasu skutecznego wniesienia sprzeciwu lub osiągnięcia celu
   przetwarzania.</p>

<p><strong>Cel przetwarzania:</strong> Obsługa formularza kontaktowego</p>
<p><strong>Przetwarzane dane osobowe:</strong>imię, nazwisko, adres poczty elektronicznej</p>
<p><strong>Podstawa prawna: </strong>art. 6 ust. 1 lit. a RODO (przetwarzanie danych osobowych odbywa się na podstawie
                                     udzielonej zgody)</p>
<p>Podanie ww. danych osobowych jest dobrowolne, ale niezbędne w celu otrzymania odpowiedzi na zapytanie (konsekwencją
   ich niepodania będzie niemożność otrzymania odpowiedzi).</p>
<p>Administrator będzie przetwarzał ww. dane osobowe do czasu cofnięcia udzielonej zgody, nie dłużej jednak niż do
   momentu udzielenia odpowiedzi na zapytanie.</p>

<p><strong>Cel przetwarzania:</strong> Spełnianie obowiązków podatkowych (m.in. wystawienie faktury VAT, przechowywanie
                                       dokumentacji księgowej)</p>
<p><strong>Przetwarzane dane osobowe:</strong>imię i nazwisko / firma, adres zamieszkania/siedziby, NIP, numer
                                              Zamówienia;</p>
<p><strong>Podstawa prawna: </strong>art. 6 ust. 1 lit. c RODO (przetwarzanie jest niezbędne do wypełnienia obowiązku
                                     prawnego ciążącego na Administratorze, w tym przypadku obowiązków wynikających z
                                     prawa podatkowego)</p>
<p>Podanie ww. danych osobowych jest dobrowolne, ale niezbędne w celu spełnienia przez Administratora ciążących na nim
   obowiązków podatkowych (konsekwencją ich niepodania będzie niemożność spełnienia przez Administratora ww.
   obowiązków).</p>
<p>Administrator będzie przetwarzał ww. dane osobowe przez okres 5 lat od końca roku, w którym upłynął termin zapłaty
   podatku za rok poprzedni.</p>

<p><strong>Cel przetwarzania:</strong> Ustalenie, dochodzenie lub obrona przed roszczeniami</p>
<p><strong>Przetwarzane dane osobowe:</strong>imię, nazwisko, firma, adres e-mail, adres zamieszkania/siedziby, numer
                                              PESEL/KRS, NIP.</p>
<p><strong>Podstawa prawna: </strong>art. 6 ust. 1 lit. f RODO (przetwarzanie jest niezbędne w celu realizacji prawnie
                                     uzasadnionego interesu Administratora, w tym przypadku ustalenia, dochodzenia lub
                                     obrony przed roszczeniami mogącymi powstać w związku z wykonywaniem Umów zawartych
                                     z Administratorem))</p>
<p>Podanie ww. danych osobowych jest dobrowolne, ale niezbędne w celu ustalenia, dochodzenia lub obrony przed
   roszczeniami mogącymi powstać w związku z wykonywaniem Umów zawartych z Administratorem (konsekwencją niepodania ww.
   danych będzie niemożność podjęcia przez Administratora ww. działań)</p>
<p>Administrator będzie przetwarzał ww. dane osobowe do czasu upływu terminów przedawnienia roszczeń mogącymi powstać w
   związku z wykonywaniem umów zawartych z Administratorem.</p>

<p><strong>Cel przetwarzania:</strong> Analiza Twojej aktywności w Sklepie</p>
<p><strong>Przetwarzane dane osobowe:</strong>data i godzina odwiedzin, numer IP urządzenia, rodzaj systemu operacyjnego
                                              urządzenia, przybliżona lokalizacja, rodzaj przeglądarki internetowej,
                                              czas spędzony w Sklepie, przeglądane Produkty, odwiedzone podstrony oraz
                                              inne działania podejmowane w ramach Sklepu.</p>
<p><strong>Podstawa prawna: </strong>art. 6 ust. 1 lit. f RODO (przetwarzanie jest niezbędne w celu realizacji prawnie
                                     uzasadnionego interesu Administratora, w tym przypadku uzyskania informacji o
                                     Twojej aktywności w Sklepie)</p>
<p>Podanie ww. danych osobowych jest dobrowolne, ale niezbędne w celu uzyskania przez Administratora informacji o Twojej
   aktywności w Sklepie (konsekwencją ich niepodania będzie niemożność uzyskania przez Administratora ww.
   informacji).</p>
<p>Administrator będzie przetwarzał ww. dane osobowe do czasu skutecznego wniesienia sprzeciwu lub osiągnięcia celu
   przetwarzania.</p>

<p><strong>Cel przetwarzania:</strong> Administrowanie Sklepem</p>
<p><strong>Przetwarzane dane osobowe:</strong>adres IP, data i czas serwera, informacje o przeglądarce internetowej,
                                              informacje o systemie operacyjnym. Powyższe dane te są zapisywane
                                              automatycznie w tzw. logach serwera, przy każdorazowym korzystaniu ze
                                              Sklepu (administrowanie nim bez użycia logów serwera i automatycznego
                                              zapisu nie byłoby możliwe).</p>
<p><strong>Podstawa prawna: </strong>art. 6 ust. 1 lit. f RODO (przetwarzanie jest niezbędne w celu realizacji prawnie
                                     uzasadnionego interesu Administratora, w tym przypadku zapewnienia prawidłowego
                                     działania Sklepu)</p>
<p>Podanie ww. danych osobowych jest dobrowolne, ale niezbędne w celu zapewnienia prawidłowego działania Sklepu
   (konsekwencją ich niepodania będzie niemożność zapewnienia działania Sklepu w sposób prawidłowy).</p>
<p>Administrator będzie przetwarzał ww. dane osobowe do czasu skutecznego wniesienia sprzeciwu lub osiągnięcia celu
   przetwarzania.</p>

<p><strong>Profilowanie</strong></p>

<p>W celu stworzenia Twojego profilu dla celów marketingowych oraz kierowania do Ciebie marketingu bezpośredniego
   dostosowanego do Twoich preferencji, Administrator będzie przetwarzał Twoje dane osobowe w sposób zautomatyzowany, w
   tym także je profilował – nie będzie to jednak wywoływać wobec Ciebie żadnych skutków prawnych, ani w podobny sposób
   istotnie wpływać na Twoją sytuację.</p>

<p>Zakres profilowanych danych osobowych odpowiada zakresowi wskazanemu powyżej w odniesieniu do analizy Twojej
   aktywności w Sklepie oraz danych, które zapiszesz na Koncie.</p>

<p>Podstawą prawną przetwarzania danych osobowych w powyższym celu jest art. 6 ust. 1 lit. f RODO, zgodnie z którym
   Administrator może przetwarzać dane osobowe w celu realizacji swojego prawnie uzasadnionego interesu, w tym przypadku
   prowadzenia działań marketingowych dopasowanych do preferencji odbiorców. Podanie ww. danych osobowych jest
   dobrowolne, lecz niezbędne do realizacji ww. celu (konsekwencją ich niepodania będzie niemożność prowadzenia przez
   Administratora działań marketingowych dopasowanych do preferencji odbiorców).</p>

<p>Administrator będzie przetwarzał dane osobowe w celu ich profilowania do czasu skutecznego wniesienia sprzeciwu lub
   osiągnięcia celu przetwarzania.</p>

<p><strong>Odbiorcy danych osobowych</strong></p>

<p>Odbiorcami danych osobowych będą następujące podmioty zewnętrzne współpracujące z Administratorem:</p>

<ol>
  <li>firma hostingowa;</li>
  <li>operator logistyczny i firmy kurierskie;</li>
  <li>dostawcy systemów płatności online;</li>
  <li>firmy dostarczające narzędzia służące do analizy aktywności w Sklepie i kierowania
      marketingu bezpośredniego do osób z niego korzystających (m.in. Google Analytics);
  </li>
  <li>firma świadcząca usługi księgowe;</li>
</ol>

<p>Ponadto, dane osobowe mogą zostać przekazane również podmiotom publicznym lub
   prywatnym, jeśli taki obowiązek będzie wynikał z powszechnie obowiązujących przepisów prawa,
   prawomocnego wyroku sądu lub prawomocnej decyzji administracyjnej.</p>

<p><strong>Przekazywanie danych osobowych do państwa trzeciego</strong></p>

<p>W związku z korzystaniem przez Administratora narzędzi takich jak Google Analytics, Twoje
   dane osobowe mogą być przekazywane do następujących państw trzecich: USA, Chile, Singapur
   oraz Tajwan (Republika Chińska). Podstawą przekazania danych do ww. państw trzecich są
   klauzule umowne zapewniające odpowiedni poziom ochrony, zgodne ze standardowymi
   klauzulami umownymi określonymi w decyzji Komisji Europejskiej z dnia 5 lutego 2010 r. w
   sprawie standardowych klauzul umownych dotyczących przekazywania danych osobowych
   podmiotom przetwarzającym dane mającym siedzibę w krajach trzecich na mocy dyrektywy
   95/46/WE Parlamentu Europejskiego i Rady.</p>

<p>Możesz uzyskać od Administratora kopię danych przekazywanych do państwa trzeciego.</p>

<p><strong>Uprawnienia</strong></p>

<p>W związku z przetwarzaniem danych osobowych, przysługują Ci następujące uprawnienia:</p>

<ol>
  <li>prawo do informacji, jakie dane osobowe Ciebie dotyczące są przetwarzane przez Administratora oraz do otrzymania
      kopii tych danych (tzw. prawo dostępu). Wydanie pierwszej kopii danych jest darmowe, za kolejne Administrator może
      naliczyć opłatę;
  </li>
  <li>jeżeli przetwarzane dane staną się nieaktualne lub niekompletne (lub w inny sposób niepoprawne) masz prawo zażądać
      ich sprostowania;
  </li>
  <li>w pewnych sytuacjach możesz zwrócić się do Administratora o usunięcie swoich danych
      osobowych, np. gdy:
  </li>
  <ol>
    <li>dane przestaną być potrzebne Administratorowi do celów, o których poinformował;</li>
    <li>skutecznie cofnąłeś zgodę na przetwarzanie danych - o ile Administrator nie ma prawa przetwarzać danych na innej
        podstawie prawnej;
    </li>
    <li>przetwarzanie jest niezgodne z prawem;</li>
    <li>konieczność usunięcia danych wynika z ciążącego na Administratorze obowiązku prawnego;</li>
  </ol>
  <li>w przypadku, gdy dane osobowe są przetwarzane przez Administratora na podstawie udzielonej zgody na przetwarzanie
      albo w celu wykonania Umowy z nim zawartej, masz prawo przenieść swoje dane do innego administratora;
  </li>
  <li>w przypadku, gdy dane osobowe przetwarzane są przez Administratora na podstawie udzielonej przez Ciebie zgody na
      przetwarzanie, masz prawo cofnąć tę zgodę w każdym momencie (cofnięcie zgody nie wpływa na zgodność z prawem
      przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem);
  </li>
  <li>jeśli uznasz, że przetwarzane dane osobowe są nieprawidłowe, ich przetwarzanie jest niezgodne z prawem, lub
      Administrator nie potrzebuje już określonych danych, możesz zażądać, aby przez określony, potrzebny czas (np.
      sprawdzenia poprawności danych lub dochodzenia roszczeń) Administrator nie dokonywał na danych żadnych operacji, a
      jedynie je przechowywał;
  </li>
  <li>masz prawo do wyrażenia sprzeciwu wobec przetwarzania danych osobowych, których
      podstawą przetwarzania jest prawnie uzasadniony interes Administratora. W razie
      skutecznego wniesienia sprzeciwu, Administrator przestanie przetwarzać dane osobowe
      w ww. celu;
  </li>
  <li>masz prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, gdy
      uznasz, że przetwarzanie danych osobowych narusza przepisy RODO.
  </li>
</ol>

<p><strong>Pliki cookies</strong></p>

<ol>
  <li>Administrator informuje, że Sklep korzysta z plików „cookies” (ciasteczka),
      instalowanych w Twoim urządzeniu końcowym. Są to niewielkie pliki tekstowe, które
      mogą być one odczytywane przez system Administratora, a także przez systemy należące
      do innych podmiotów, z których usług korzysta Administrator (np. Facebooka, Google’a).
  </li>
  <li>Administrator wykorzystuje pliki cookies w następujących celach:
    <ol>
      <li>
        zapewnienie prawidłowego działania Sklepu – dzięki plikom cookies, możliwe jest
        sprawne działanie Sklepu, korzystanie z jego funkcji oraz wygodne przemieszczanie się
        między poszczególnymi podstronami;
      </li>
      <li>zwiększenie komfortu przeglądania Sklepu – dzięki plikom cookies możliwe jest
          wykrywanie błędów na niektórych podstronach oraz ich stałe ulepszanie;
      </li>
      <li>tworzenie statystyk– pliki cookies są wykorzystywane w celu dokonania analizy
          sposobu korzystania przez użytkowników ze Sklepu. Dzięki temu możliwe jest stałe
          ulepszanie Sklepu i dostosowywanie jego działania do preferencji użytkowników;
      </li>
      <li>prowadzenia działań marketingowych – dzięki plikom cookies, Administrator może
          kierować do użytkowników reklamy dostosowane do ich preferencji.
      </li>
    </ol>
  </li>
  <li>Administrator może umieszczać w Twoim urządzeniu zarówno pliki trwałe, jak i
      tymczasowe (sesyjne). Pliki sesyjne są zazwyczaj usuwane z chwilą zamknięcia
      przeglądarki, natomiast zamknięcie przeglądarki nie powoduje usunięcia plików trwałych.
  </li>
  <li>Informacja o plikach cookies wykorzystywanych przez Administratora wyświetlana jest w
      panelu znajdującym się w dolnej części strony internetowej Sklepu. W zależności od
      Twojej decyzji, możesz włączyć lub wyłączyć pliki cookies poszczególnych kategorii (z
      wyjątkiem niezbędnych plików cookies) oraz zmieniać te ustawienia w każdym czasie.
  </li>
  <li>Dane zbierane za pomocą plików cookies nie pozwalają Administratorowi na Twoją
      identyfikację.
  </li>
  <li>Administrator korzysta z następujących plików cookies lub wykorzystujących je narzędzi:
    <ol>
      <li>Niezbędne pliki cookies:
        <ol>
          <li>dostawca: Administrator;</li>
          <li>funkcje i zakres pobieranych danych: Działanie tych plików jest niezbędne do prawidłowego funkcjonowania
              strony internetowej Sklepu, dlatego nie możesz ich wyłączyć. Dzięki tym plikom (pobierających m.in. numer
              IP Twojego urządzenia), możliwe jest m.in. informowanie Cię o plikach cookies działających na stronie
              internetowej Sklepu.
          </li>
          <li>okres działania: Większość niezbędnych plików cookies ma charakter sesyjny, niektóre jednak pozostają w
              Twoim urządzeniu końcowym do momentu ich usunięcia;
          </li>
        </ol>
      </li>
      <li>Google Analytics
        <ol>
          <li>dostawca: Google;</li>
          <li>funkcje i zakres pobieranych danych; Narzędzie to umożliwia zbieranie danych statystycznych o sposobie
              korzystania z Sklepu przez Klientów, m.in. o liczbie odwiedzin, czasie trwania odwiedzin, stosowanej
              wyszukiwarce, lokalizacji. Zebrane dane pomagają ulepszać Sklep oraz czynić go bardziej przyjaznym dla
              Klientów.
          </li>
          <li>okres działania: do 2 lat lub do momentu ich usunięcia (w zależności o tego, które z wymienionych zdarzeń
              nastąpi wcześniej)
          </li>
        </ol>
      </li>
    </ol>
  </li>
  <li>Za pośrednictwem większości powszechnie używanych przeglądarek możesz sprawdzić,
      czy na Twoim urządzeniu końcowym zostały zainstalowane pliki cookies, jak również
      usunąć zainstalowane pliki cookies oraz zablokować instalowanie ich w przyszłości przez
      Sklep. Wyłączenie lub ograniczenie obsługi plików cookies może jednak spowodować
      dość poważne trudności w korzystaniu ze Sklepu, np. w postaci konieczności logowania
      się na każdej podstronie, dłuższego okresu ładowania się strony Sklepu, ograniczeń w
      korzystaniu z niektórych funkcjonalności.
  </li>
</ol>

<p><strong>Postanowienia końcowe</strong></p>

<p>W zakresie nieuregulowanym Polityką, stosuje się powszechnie obowiązujące przepisy o ochronie
   danych osobowych.</p>

<p>Polityka obowiązuje od dnia 22.12.2023 r.</p>
