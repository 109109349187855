import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {ShoppingState} from './shopping.reducer';
import {shoppingSelectors} from './shopping.selectors';
import {shoppingActions} from './shopping.actions';
import {filter, map} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {CartProduct} from '../../model/cart';
import {Util} from '../../common/util';
import {Product} from '../../model/product';
import {CheckoutType} from '../../model/checkout-type';
import {DeliveryType} from '../../model/delivery-type';
import {PackageMachine} from '../../model/package-machine';
import {DeliveryData} from '../../model/delivery-data';
import {PaymentType} from '../../model/payment-type';
import {PricedDelivery} from '../../model/delivery';
import {CheckoutStep} from '../../model/checkout-step';
import {PaymentIntent} from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class ShoppingFacade {
  categories$ = this.store.pipe(select(shoppingSelectors.categories));
  products$ = this.store.pipe(select(shoppingSelectors.products));
  deliveries$ = this.store.pipe(select(shoppingSelectors.deliveries));
  checkoutStep$ = this.store.pipe(select(shoppingSelectors.checkoutStep));
  selectedPackageMachine$ = this.store.pipe(select(shoppingSelectors.selectedPackageMachine));
  cartNullable$ = this.store.pipe(select(shoppingSelectors.cart));
  cart$ = this.store.pipe(select(shoppingSelectors.cart)).pipe(filter(Util.isNonNull));
  checkoutType$ = this.store.pipe(select(shoppingSelectors.checkoutType)).pipe(filter(Util.isNonNull));
  paymentIntentNullable$ = this.store.pipe(select(shoppingSelectors.paymentIntent));
  paymentIntent$ = this.paymentIntentNullable$.pipe(filter(Util.isNonNull));
  deliveryType$ = this.store.pipe(select(shoppingSelectors.deliveryType)).pipe(filter(Util.isNonNull));
  paymentType$ = this.store.pipe(select(shoppingSelectors.paymentType)).pipe(filter(Util.isNonNull));
  nextStepLoading$ = this.store.pipe(select(shoppingSelectors.nextStepLoading)).pipe(filter(Util.isNonNull));
  productsFromCart$: Observable<Product[]> = combineLatest([
    this.products$,
    this.cart$
  ]).pipe(
    map(([products, cart]) => Util.convertCartProductsToFullProducts(cart.products, products))
  )
  totalSum$: Observable<number> = this.productsFromCart$.pipe(
    map(products => products.reduce((result, product) => (product?.price * product?.quantity) + result, 0))
  );
  pricedDeliveries$: Observable<PricedDelivery[]> = combineLatest([
    this.totalSum$,
    this.deliveries$,
  ]).pipe(
    map(([totalSum, deliveries]) => deliveries
      .map(delivery => ({
        ...delivery, price: delivery.prices
          .reduce((result, price) =>
            (price.to < totalSum && result.to <= price.to) ? price : result, {value: 0, to: 0}).value
      }))
    ));

  categoriesWithProducts$ = combineLatest([
    this.categories$,
    this.products$,
  ]).pipe(
    map(([categories, products]) => categories.map(category => ({
      ...category,
      products: products.filter(product => product.categoryId === category.id)
    }))),
  );

  selectedDelivery$: Observable<PricedDelivery> = combineLatest([
    this.deliveryType$,
    this.pricedDeliveries$
  ]).pipe(
    map(([deliveryType, pricedDeliveries]) => pricedDeliveries.find(({type}) => type === deliveryType)),
    filter(Util.isNonNull)
  )

  constructor(private store: Store<ShoppingState>) {
  }

  getCategories() {
    this.store.dispatch(shoppingActions.getCategories());
  }

  getProducts() {
    this.store.dispatch(shoppingActions.getProducts());
  }

  getCart(cartId: string) {
    this.store.dispatch(shoppingActions.getCart({cartId}));
  }

  createCart() {
    this.store.dispatch(shoppingActions.createCart());
  }

  addProductToCart(cartProduct: CartProduct) {
    this.store.dispatch(shoppingActions.addProductToCart({cartProduct}));
  }

  removeProductFromCart(productId: string) {
    this.store.dispatch(shoppingActions.removeProductFromCart({productId}));
  }

  changeCheckoutType(checkoutType: CheckoutType) {
    this.store.dispatch(shoppingActions.changeCheckoutType({checkoutType}));
  }

  changeDeliveryType(deliveryType: DeliveryType) {
    this.store.dispatch(shoppingActions.changeDeliveryType({deliveryType}));
  }

  selectPackageMachine(packageMachine: PackageMachine) {
    this.store.dispatch(shoppingActions.selectPackageMachine({packageMachine}));
  }

  clearSelectedPackageMachine() {
    this.store.dispatch(shoppingActions.clearSelectedPackageMachine());
  }

  changeCheckoutStep(step: Optional<CheckoutStep>) {
    this.store.dispatch(shoppingActions.changeCheckoutStep({step}));
  }

  performPayment() {
    this.store.dispatch(shoppingActions.performPayment());
  }

  goToSummary(deliveryData: DeliveryData) {
    this.store.dispatch(shoppingActions.goToSummary({deliveryData}));
  }

  changePaymentType(paymentType: PaymentType) {
    this.store.dispatch(shoppingActions.changePaymentType({paymentType}));
  }

  confirmOrder() {
    this.store.dispatch(shoppingActions.confirmOrder());
  }

  updateDeliveryData(deliveryData: DeliveryData) {
    this.store.dispatch(shoppingActions.updateDeliveryData({deliveryData}));
  }
}
