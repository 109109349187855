export enum OrderStatus {
  Created = 'CREATED',
  Paid = 'PAID',
  Sent = 'SENT',
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Completed = 'COMPLETED',
}

export const orderStatusDict: any = {
  [OrderStatus.Created]: 'Utworzone',
  [OrderStatus.Paid]: 'Zapłacone',
  [OrderStatus.Sent]: 'Wysłane',
  [OrderStatus.Delivered]: 'Dostarczone',
  [OrderStatus.Failed]: 'Błędne',
  [OrderStatus.Completed]: 'Zakończone',
}

export const orderStatusActionName: any = {
  [OrderStatus.Created]: 'Oznacz jako zapłacone',
  [OrderStatus.Paid]: 'Oznacz jako wysłane',
  [OrderStatus.Sent]: 'Oznacz jako dostarczone',
  [OrderStatus.Delivered]: 'Archiwizuj',
  [OrderStatus.Failed]: 'Usuń',
  [OrderStatus.Completed]: 'Usuń',
}
