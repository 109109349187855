<button mat-button
        mat-icon-button
        [matBadge]="productsQuantity"
        [matBadgeHidden]="!productsQuantity"
        (mouseenter)="mouseEnter()"
        (mouseleave)="mouseLeave()"
        matBadgeSize="small"
        matBadgePosition="below after"
        matBadgeColor="warn">
  <mat-icon>shopping_cart</mat-icon>
</button>

<section *ngIf="formGroup && mouseIn"
         class="cart-menu"
         [formGroup]="formGroup"
         (mouseenter)="mouseEnter()"
         (mouseleave)="mouseLeave()"
         @fadeIn>
  <mat-card class="cart-products">
    <h4 class="special">
      <span>Koszyk:</span>
      <button mat-button
              mat-icon-button
              (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </h4>
    <ng-container *ngIf="products?.length">
      <app-cart-content [products]="products"
                        [totalSum]="totalSum"
                        (productRemove)="productRemove.emit($event)"
                        (checkout)="mouseLeave()"
                        (quantityChange)="quantityChange.emit($event)"
                        class="foldable"></app-cart-content>
    </ng-container>
    <ng-container *ngIf="!products?.length">
      <p class="no-products">Brak produktów w koszyku. Może coś tutaj dodasz?</p>
    </ng-container>
  </mat-card>
</section>
