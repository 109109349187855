import {createAction, props} from '@ngrx/store';
import {Category} from '../../model/category';
import {Product} from '../../model/product';
import {Delivery} from '../../model/delivery';

const saveCategory = createAction('[Admin] Save category', props<{category: Category}>());
const saveProduct = createAction('[Admin] Save product', props<{product: Product}>());
const saveDelivery = createAction('[Admin] Save delivery', props<{delivery: Delivery}>());
const deleteCategory = createAction('[Admin] Delete category', props<{categoryId: string}>());
const deleteProduct = createAction('[Admin] Delete product', props<{productId: string}>());
const deleteDelivery = createAction('[Admin] Delete delivery', props<{deliveryId: string}>());

export const adminActions = {
  saveCategory,
  saveProduct,
  saveDelivery,
  deleteCategory,
  deleteProduct,
  deleteDelivery,
};
