import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CategoriesComponent} from './component/categories/categories.component';
import {ProductsComponent} from './component/products/products.component';
import {SharedModule} from '../shared/shared.module';
import {ProductDetailsComponent} from './component/product-details/product-details.component';
import {CartComponent} from './component/cart/cart.component';
import {AngularInpostGeowidgetModule} from 'angular-inpost-geowidget';
import {PackageMachineSelectComponent} from './component/package-machine-select/package-machine-select.component';
import {DeliveryComponent} from './component/delivery/delivery.component';
import {CheckoutSummaryComponent} from './component/checkout-summary/checkout-summary.component';
import {SelectedMachineComponent} from './component/selected-machine/selected-machine.component';
import {CheckoutLoginComponent} from './component/checkout-login/checkout-login.component';
import {CheckoutDeliveryComponent} from './component/checkout-delivery/checkout-delivery.component';
import {ConfirmationContentComponent} from './component/confirmation-content/confirmation-content.component';
import {AuthModule} from '../auth/auth.module';


@NgModule({
  declarations: [
    CategoriesComponent,
    ProductsComponent,
    ProductDetailsComponent,
    CartComponent,
    PackageMachineSelectComponent,
    DeliveryComponent,
    CheckoutSummaryComponent,
    SelectedMachineComponent,
    CheckoutLoginComponent,
    CheckoutDeliveryComponent,
    ConfirmationContentComponent,
  ],
  exports: [
    CategoriesComponent,
    ProductsComponent,
    ProductDetailsComponent,
    CartComponent,
    CheckoutLoginComponent,
    DeliveryComponent,
    CheckoutSummaryComponent,
    CheckoutDeliveryComponent,
    ConfirmationContentComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,
    AngularInpostGeowidgetModule,
    AuthModule,
  ]
})
export class ShoppingModule {
}
