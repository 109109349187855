import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {apiUrlToken} from '@@common';
import {Observable} from 'rxjs';
import {Category} from '../../model/category';
import {Product} from '../../model/product';
import {fromPromise} from 'rxjs/internal-compatibility';
import {Delivery} from '../../model/delivery';
import {AngularFirestore} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AdminDataService {
  constructor(private http: HttpClient,
              private firestore: AngularFirestore,
              @Inject(apiUrlToken) private api: string) {
  }

  updateCategory(category: Category): Observable<void> {
    return fromPromise(this.firestore.collection<Category>('categories')
      .doc(category.id)
      .set(category, {merge: true}))
  }

  updateProduct(product: Product): Observable<void> {
    return fromPromise(this.firestore.collection<Product>('products')
      .doc(product.id)
      .set({...product}, {merge: true}))
  }

  deleteCategory(categoryId: string): Observable<void> {
    return fromPromise(this.firestore.collection<Category>('categories')
      .doc(categoryId)
      .delete())
  }

  deleteProduct(productId: string): Observable<void> {
    return fromPromise(this.firestore.collection<Product>('products')
      .doc(productId)
      .delete())
  }

  saveCategory(category: Category): Observable<void> {
    return fromPromise(this.firestore.collection<Category>('categories')
      .doc(category.id)
      .set(category, {merge: true}));
  }

  saveProduct(product: Product): Observable<void> {
    return fromPromise(this.firestore.collection<Product>('products')
      .doc(product.id)
      .set(product, {merge: true}));
  }

  saveDelivery(delivery: Delivery): Observable<void> {
    const deliveryId = delivery.id ?? this.firestore.createId();
    return fromPromise(this.firestore.doc<Delivery>(`deliveries/${deliveryId}`)
      .set({...delivery, id: deliveryId}, {merge: true}));
  }

  deleteDelivery(deliveryId: string): Observable<void> {
    return fromPromise(this.firestore.doc<Product>(`deliveries/${deliveryId}`)
      .delete())
  }
}
