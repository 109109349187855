export const environment = {
  production: true,
  stripeKey: 'pk_live_51JP7b3Err06FT9ZGRdt2Iy0cnoRPLMYRBZrwImU3z3hBqtbw56I87gXEkYAuJSbRp3nLwQLACyQM6CxCrvgNRjbV00u7EINoVM',
  firebase: {
    apiKey: "AIzaSyCFLKCdzot6hN9SJ-wO3oOrjgoGMAULgQM",
    authDomain: "empatai.pl",
    projectId: "babilas-8fb45",
    storageBucket: "babilas-8fb45.appspot.com",
    messagingSenderId: "539979241083",
    appId: "1:539979241083:web:fe792afdf05bf76491b4de",
    measurementId: "G-HGE4Y514G1"
  }
};
