import {Action, createReducer} from '@ngrx/store';

export const ADMIN_FEATURE_KEY = 'admin';

export interface AdminState {
}

export const initialState: AdminState = {
};

const reducer = createReducer(
  initialState,
);

export function adminReducer(
  state: AdminState | undefined,
  action: Action
): AdminState {
  return reducer(state, action);
}
